import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import log from 'loglevel'

// src
import { AddUserInput } from '~/src/models/apis/private'

type EmailSendingState =
  'idle' |
  'sending' |
  'sent' |
  'failed'

type EmailLinkSigningState =
  'idle' |
  'signing' |
  'signed' |
  'failed'

interface SignupStateStore {
  emailSendingState: EmailSendingState
  emailLinkSigningState: EmailLinkSigningState
  email?: string
}

const initialState: SignupStateStore = {
  emailSendingState: 'idle',
  emailLinkSigningState: 'idle'
}

type CompleteSignupInput = AddUserInput & {
  password: string
}

const sliceName = 'signup'

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // Eメールリンクを送信する
    sendEmailLink: (state, action: PayloadAction<{ email: string }>) => {
      log.debug(`action - ${sliceName}/sendEmailLink`, action)
      const { email } = action.payload
      state.emailSendingState = 'sending'
      state.email = email
    },
    // Eメールリンク送信成功
    sendingEmailLinkIsSuccessful: (state) => {
      log.debug(`action - ${sliceName}/sendingEmailLinkIsSuccessful`)
      state.emailSendingState = 'sent'
    },
    // Eメールリンク送信失敗
    sendingEmailLinkIsFailed: (state, action: PayloadAction<{ code: string, err?: Error }>) => {
      log.debug(`action - ${sliceName}/sendingEmailLinkIsFailed`, action)
      state.emailSendingState = 'failed'
    },

    // Eメールリンクでサインインする
    signInWithEmailLink: (state) => {
      log.debug(`action - ${sliceName}/signInWithEmailLink`)
      state.emailLinkSigningState = 'signing'
    },
    // Eメールリンクによるサインイン成功
    signingEmailLinkIsSuccessful: (state) => {
      log.debug(`action - ${sliceName}/signingEmailLinkIsSuccessful`)
      state.emailLinkSigningState = 'signed'
    },
    // Eメールリンクによるサインイン失敗
    signingEmailLinkIsFailed: (state, action: PayloadAction<{ code: string, err?: Error }>) => {
      log.debug(`action - ${sliceName}/signingEmailLinkIsFailed`, action)
      state.emailLinkSigningState = 'failed'
    },

    // アカウント登録を完了する
    completeSignup: (state, action: PayloadAction<{ input: CompleteSignupInput }>) => {
      log.debug(`action - ${sliceName}/completeSignup`, action)
    },
    // エラー
    signupError: (state, action: PayloadAction<{ code: string, err?: Error }>) => {
      log.warn(`action - ${sliceName}/error`, action)
    }
  }
})

// Actions
export const actions = slice.actions

// Reducer
export default slice.reducer

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

// mui
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

// icons
import AddCardIcon from '@mui/icons-material/AddCard'
import PersonIcon from '@mui/icons-material/Person'
import RestoreIcon from '@mui/icons-material/Restore'

// ./
import { RequireAuth } from '~src/functionals/auth'
import { Layout, LayoutProps } from './base'

// ナビレイアウト
type NavLayoutProps = {
  // TODO :: 必要があれば追加
} & Omit<LayoutProps, 'footerComponent'>

export function NavLayout (props: NavLayoutProps) {
  const {
    children,
    ...rest
  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Layout {...rest}
      footerComponent={() => (
        <BottomNavigation
          showLabels={true}
          value={location.pathname}
          onChange={(event, newValue) => {
            event.preventDefault()
            navigate(newValue)
          }}
          sx={{
            /* bgcolor: 'transparent',
            '& .MuiBottomNavigationAction-root, .MuiBottomNavigationAction-root > svg': (theme) => ({
              color: theme.palette.text.secondary
            }),
            '& .Mui-selected, .Mui-selected > svg': (theme) => ({
              color: theme.palette.primary.contrastText
            }) */
          }}
        >
          <BottomNavigationAction label={t('nav.cards')} value="/app/cards" icon={<AddCardIcon />} />
          <BottomNavigationAction label={t('nav.recents')} value="/app/recents" icon={<RestoreIcon />} />
          <BottomNavigationAction label={t('nav.accounts')} value="/app/accounts" icon={<PersonIcon />} />
        </BottomNavigation>
      )}
    >
      <RequireAuth>
        {children}
      </RequireAuth>
    </Layout>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'
import log from 'loglevel'

// src
import { DeleteUserInput, UserAccount } from '~/src/models/apis/private'

type UserState =
  'idle' |
  'signed-in' |
  'signed-out'

type UserAccountState =
  'idle' |
  'registration-incomplete' |
  'registration-completed'

interface AuthStateStore {
  userState: UserState
  user?: User
  accountState: UserAccountState
  account?: UserAccount
}

const initialState: AuthStateStore = {
  userState: 'idle',
  accountState: 'idle'
}

const sliceName = 'auth'

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // サインインする(Eメール)
    signIn: (state, action: PayloadAction<{ email: string, password: string, token: string }>) => {
      log.debug(`action - ${sliceName}/signIn`)
    },
    // サインインした(状態になった)
    signedIn: (state, action: PayloadAction<{ user: User }>) => {
      const { user } = action.payload
      log.debug(`action - ${sliceName}/signedIn`)
      state.user = user
      state.userState = 'signed-in'
    },
    // サインアウトする
    signOut: (state) => {
      log.debug(`action - ${sliceName}/signOut`)
    },
    // サインアウトされた(状態になった)
    signedOut: (state) => {
      log.debug(`action - ${sliceName}/signedOut`)
      state.user = undefined
      state.userState = 'signed-out'
      state.account = undefined
      state.accountState = 'idle'
    },
    // ユーザ情報取得開始
    fetchUserAccount: (state) => {
      log.debug(`action - ${sliceName}/fetchUserAccount`)
    },
    // ユーザ情報取得
    userAccountFetched: (state, action: PayloadAction<{ account?: UserAccount }>) => {
      log.debug(`action - ${sliceName}/accountLoaded`, action.payload)
      const { account } = action.payload
      state.accountState = account ? 'registration-completed' : 'registration-incomplete'
      state.account = account
    },
    // ユーザ情報削除処理開始
    deleteUserAccount: (state, action: PayloadAction<{ input: DeleteUserInput }>) => {
      log.debug(`action - ${sliceName}/deleteUserAccount`)
    },
    // エラー
    authError: (state, action: PayloadAction<{ code: string, err?: Error }>) => {
      log.warn(`action - ${sliceName}/authError`, action.payload)
    }
  }
})

// Actions
export const actions = slice.actions

// Reducer
export default slice.reducer

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// icons
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import SendIcon from '@mui/icons-material/Send'

// src
import { useAppDispatch, useRootSelector } from '~/src/stores'
import { actions } from '~src/stores/slices/signup'

export type SignUpSendingProps = {
  children?: React.ReactNode,
}

export function SignUpSending (props: SignUpSendingProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const emailSending = useRootSelector(state => state.signup.emailSendingState)
  const email = useRootSelector(state => state.signup.email)

  if (email == null) {
    return <Navigate to="../new" replace />
  }

  const handleSendEmailVerification = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(actions.sendEmailLink({ email }))
  }

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    navigate(-1)
  }

  const renderMessage = () => {
    switch (emailSending) {
      case 'failed':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <CancelScheduleSendIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-sending.message-failed')}
            </Typography>
          </>
        )

      case 'sent':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <MarkEmailReadIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-sending.message-sent')}
            </Typography>
          </>
        )

      case 'sending':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <SendIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-sending.message-send')}
            </Typography>
          </>
        )

      default:
        return (
          <></>
        )
    }
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* タイトルメッセージ */}
        {renderMessage()}

        {/* 再送 */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleSendEmailVerification}
          sx={{ mt: 3, mb: 2 }}
        >
          {t('signup-sending.button-resend')}
        </Button>

        {/* 戻る */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleBack}
          sx={{ mt: 3 }}
        >
          {t('signup-sending.button-back')}
        </Button>

      </Box>
    </>
  )
}

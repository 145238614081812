import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// src
import { useRootSelector } from '~/src/stores'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useUpdateUser } from '~src/hooks/update-user'

type ConfirmModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

function ConfirmModal (props: ConfirmModalProps) {
  const { t } = useTranslation()
  const { open, onConfirm, onClose } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('edit-account-confirm.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('edit-account-confirm.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('edit-account-confirm.close')}</Button>
        <Button onClick={onConfirm}>{t('edit-account-confirm.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

type ResultModalProps = {
  open: boolean;
  onClose: () => void;
}

function ResultModal (props: ResultModalProps) {
  const { t } = useTranslation()
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('edit-account-result.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('edit-account-result.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('edit-account-result.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export function EditAccount () {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const account = useRootSelector(state => state.auth.account)!

  const [userName, setUserName] = useState(account.userName)
  const [kana, setKana] = useState(account.kana ? account.kana : '')
  const [invalidUserName, setInvalidUserName] = useState<boolean>()
  const [invalidKana, setInvalidKana] = useState<boolean>()

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const { isValidating, mutate, data, errorInfo: err, reset } = useUpdateUser()

  const submitNotAllowed = (
    invalidUserName == null ||
    invalidKana == null ||
    invalidUserName ||
    invalidKana
  )

  const invalidParam = (userName: string, kana: string) => {
    setInvalidUserName(userName.trim().length <= 0)
    setInvalidKana(false)
  }

  // 名前(UserName)を検証
  const handleUserNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setUserName(text)
    invalidParam(text, kana)
  }

  // カナ(kana)を検証
  const handleKanaChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setKana(text)
    invalidParam(userName, text)
  }

  // 更新[Form]
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    setConfirmModalOpen(true)
  }

  // 戻る[Form]
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    navigate('../')
  }

  // 実行[ConfirmModal]
  const handleConfirmModalSubmit = () => {
    setConfirmModalOpen(false)

    mutate({
      userName,
      kana
    }).catch(_ => { })
  }

  // キャンセル[ConfirmModal]
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false)
  }

  // 閉じる[ResultModal]
  const handleResultModalClose = () => {
    reset()
    navigate(-1)
  }

  return (
    <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      {/* タイトルメッセージ */}
      <Typography component="h1" variant="h5">
        {t('edit-account.title')}
      </Typography>

      {/* アラート表示 */}
      {(!isValidating && err) &&
        <Stack sx={{ width: '100%', mt: 3 }}>
          <Alert severity="error" onClose={() => reset()}>
            {ErrorInfoMessage(err)}
          </Alert>
        </Stack>
      }

      {/* 説明 */}
      <Typography variant="body2" sx={{ mt: 2 }}>
        {t('edit-account.description')}
      </Typography>

      {/* フォーム */}
      <Box component="form" noValidate sx={{ mt: 3 }}>

        {/* 入力欄 */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="edit-account-user-name"
              name="user-name"
              label={t('edit-account.input-user-name')}
              autoComplete={t('edit-account.autofill-user-name')}
              error={invalidUserName}
              helperText={invalidUserName ? t('validation.user-name-is-invalid') : undefined}
              onChange={handleUserNameChanged}
              value={userName}
              autoFocus
              disabled={isValidating}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="edit-account-kana"
              name="kana"
              label={t('edit-account.input-kana')}
              autoComplete={t('edit-account.autofill-kana')}
              onChange={handleKanaChanged}
              value={kana}
              disabled={isValidating}
            />
          </Grid>
        </Grid>

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={submitNotAllowed || isValidating}
        >
          {t('edit-account.submit')}
        </Button>

        <Button
          type="button"
          fullWidth
          variant="outlined"
          sx={{ mt: 3 }}
          onClick={handleCancel}
          disabled={isValidating}
        >
          {t('edit-account.back')}
        </Button>

      </Box>

      <ConfirmModal open={confirmModalOpen} onConfirm={handleConfirmModalSubmit} onClose={handleConfirmModalClose} />
      <ResultModal open={(!isValidating && data)} onClose={handleResultModalClose} />
    </Box>
  )
}

import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

// src
import { ValidationResult } from '~src/models/validations'

type PasswordFieldProps = {
  id: string
  name: string
  label: string
  autoComplete?: string
  validationResult?: ValidationResult
  readonly?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export function PasswordField (props: PasswordFieldProps) {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPasswordClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setShowPassword(!showPassword)
  }

  const showError = props.validationResult?.invalid ?? false
  const showErrorText = props.validationResult?.invalid ? t(props.validationResult?.tid!) : undefined

  return (
    <TextField
      required
      fullWidth
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} onClick={handleShowPasswordClick}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      id={props.id}
      name={props.name}
      label={props.label}
      error={showError}
      helperText={showErrorText}
      onChange={props.onChange}
      autoComplete={props.autoComplete ?? 'current-password'}
      inputProps={{
        readOnly: props.readonly
      }}
    />
  )
}

import * as React from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// mui
import CssBaseline from '@mui/material/CssBaseline'
import { enUS, jaJP } from '@mui/material/locale'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { jaJP as jaJPPickers } from '~src/locales/date-pickers-ja'
// import { red } from '@mui/material/colors'

type ColorMode = 'light' | 'dark'

let lightTheme = createTheme({
  palette: {
    mode: 'light'
    // primary: {
    //  main: red[500]
    // }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})
lightTheme = responsiveFontSizes(lightTheme)

let darkTheme = createTheme({
  palette: {
    mode: 'dark'
    // primary: {
    //  main: red[500]
    // }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})
darkTheme = responsiveFontSizes(darkTheme)

type ThemeObject = {
  toggle: () => void
}

const ThemeContext = createContext<ThemeObject>({
  toggle: () => { }
})

export function useThemeHandler () {
  return useContext(ThemeContext)
}

type ThemeProps = {
  children?: React.ReactNode
}

export function Theme (props: ThemeProps) {
  const { i18n } = useTranslation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<ColorMode>(prefersDarkMode ? 'dark' : 'light')
  const handler = useMemo<ThemeObject>(
    () => {
      return {
        toggle: () => {
          setMode((prev) => prev === 'light' ? 'dark' : 'light')
        }
      }
    },
    []
  )

  const themeWithLocale = React.useMemo(() => {
    const theme = ((mode === 'light') ? lightTheme : darkTheme)
    if (i18n.language === 'ja') {
      return createTheme(theme, jaJP, jaJPPickers)
    }
    return createTheme(theme, enUS)
  }, [mode, i18n.language])

  return (
    <ThemeContext.Provider value={handler}>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline enableColorScheme />
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

import * as React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

export function StopIcon () {
  // figmaで作成したものを使用
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 14.0796 16.9463 17.446 13 17.9381V22H11V17.9381C7.05369 17.446 4 14.0796 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10ZM17.1973 13C16.1599 14.7934 14.2208 16 12 16C9.77915 16 7.84012 14.7934 6.80269 13H17.1973ZM17.1973 7C16.1599 5.2066 14.2208 4 12 4C9.77915 4 7.84012 5.2066 6.80269 7H17.1973Z" fill="#9B9B9B" />
      </svg>
    </SvgIcon>
  )
}

export function DotsIcon () {
  // figmaで作成したものを使用
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="5" r="2" fill="#9B9B9B" />
        <circle cx="12" cy="12" r="2" fill="#9B9B9B" />
        <circle cx="12" cy="19" r="2" fill="#9B9B9B" />
      </svg>
    </SvgIcon>
  )
}

import * as React from 'react'

// mui
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

// src
import { useRootSelector } from '~/src/stores'

export function LoadingProgress () {
  const isLoading = useRootSelector(state => state.loading.isLoading)
  return (
    <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <CircularProgress size={60} />
    </Backdrop>
  )
}

type LoadingProgressSimpleProps = {
  open: boolean
}

export function LoadingProgressSimple (props: LoadingProgressSimpleProps) {
  return (
    <Backdrop open={props.open} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <CircularProgress size={60} />
    </Backdrop>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// mui
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Snackbar from '@mui/material/Snackbar'

// src
import { ErrorDetailMessage } from '~src/functionals/error'
import { useAppDispatch, useRootSelector } from '~src/stores'
import { actions, ErrorDetail } from '~src/stores/slices/error'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

// エラー表示
export function ErrorSnackbar () {
  const dispatch = useAppDispatch()
  const errors = useRootSelector(state => state.error.queue)
  const nextError = errors[0]
  const [open, setOpen] = useState(false)
  const [error, setError] = useState<ErrorDetail>()
  const { t } = useTranslation()

  // TODO :: エラー表示処理（以下は、途中）

  useEffect(() => {
    if (nextError) {
      setError(nextError)
      dispatch(actions.shift())
    }
  }, [nextError])

  useEffect(() => {
    setOpen(error != null)
  }, [error])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // 無視する
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
        <AlertTitle>{t('error-message.title')}</AlertTitle>
        {ErrorDetailMessage(error)}
      </Alert>
    </Snackbar>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export function NotFound () {
  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component="h1" variant="h1">
          404
        </Typography>

        <Typography component="h2" variant="h3">
          Not Found
        </Typography>

        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('not-found')}
        </Typography>
      </Box>
    </>
  )
}

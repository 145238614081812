import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import { Alert, Button, Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'

// src
import { EmailField } from '~src/components/email-field'
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { ReauthDialog } from '~src/components/reauth-dialog'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useChangeEmail } from '~src/hooks/change-email'
import { ValidationResult } from '~src/models/validations'

export function ChangeEmail () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [emailVR, setEmailVR] = useState<ValidationResult>()
  const [newEmail, setNewEmail] = useState<string>()
  const [reauthOpen, setReauthOpen] = useState<boolean>(false)
  const { isValidating, mutate, data, errorInfo: err, reset } = useChangeEmail()
  const emailChangeSucceeded = (!isValidating && data)

  const handleEmailValidationResultChanged = (value: ValidationResult) => {
    setEmailVR(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string | null ?? ''

    setNewEmail(email)
    setReauthOpen(true)
  }

  const handleBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(-1)
  }

  const submitNotAllowed = (
    (emailVR?.invalid ?? true) ||
    emailChangeSucceeded
  )

  return (
    <>
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('change-email.title')}
        </Typography>

        {/* 成功表示 */}
        {(!isValidating && data) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="success">
              {t('change-email.success')}
            </Alert>
          </Stack>
        }

        {/* エラー表示 */}
        {(!isValidating && err) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="error" onClose={() => reset()}>
              {ErrorInfoMessage(err)}
            </Alert>
          </Stack>
        }

        {/* 説明 */}
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('change-email.description')}
        </Typography>

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EmailField
                id="change-email"
                name="email"
                label={t('change-email.input-email')}
                onValidationResultChanged={handleEmailValidationResultChanged}
                readonly={emailChangeSucceeded}
              />
            </Grid>
          </Grid>

          {/* メールアドレス変更 */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitNotAllowed}
          >
            {t('change-email.submit')}
          </Button>

          {/* 戻る */}
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={handleBackClick}
          >
            {t('change-email.back')}
          </Button>
        </Box>

      </Box>

      {/* パスワード認証 */}
      <ReauthDialog
        open={reauthOpen}
        onClose={() => setReauthOpen(false)}
        onAccept={() => {
          mutate({ newEmail: newEmail! }).catch(_ => { })
        }}
      />

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}

import * as React from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

// mui
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// icons
import AddIcon from '@mui/icons-material/Add'
import BlockIcon from '@mui/icons-material/Block'

// src
import { useLayout } from '~src/components/layouts/base'
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { ReauthDialog } from '~src/components/reauth-dialog'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useCreditCards, useDeleteCard } from '~src/hooks/credit-cards'
import { CreditCard, PageInfo } from '~src/models/apis/private'
import { ErrorInfo } from '~src/models/apis/util'
import { CreditRegistUrl } from '~src/models/credit-system'
import { useAppDispatch } from '~src/stores'
import { actions } from '~src/stores/slices/error'

type RenderItemProps = {
  item?: CreditCard
  onDelete: () => void
}

function RenderItem (props: RenderItemProps) {
  const { item } = props
  const { t } = useTranslation()

  const brandName = item?.brandName
  const maskedNumber = item?.maskedNumber
  const isApproval = item?.isApproval

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    props.onDelete()
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Card variant="outlined">
        <CardContent sx={{ px: 2, py: 1 }}>
          <Grid container alignItems="center">
            {isApproval
              ? <Grid item xs={2}>
                <BlockIcon color='error' />
              </Grid>
              : <></>
            }
            <Grid item xs={5}>
              <Typography component="div" variant="h5">
                {brandName}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography component="div" variant="h6">
                {maskedNumber}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="outlined" size="small" color="error" onClick={handleDeleteClick}>
            {t('cards.button-delete')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

type RenderErrorProps = {
  err: ErrorInfo
}

function RenderError (props: RenderErrorProps) {
  const { err } = props
  return (
    <Stack sx={{ width: '100%' }}>
      <Alert severity="error">{ErrorInfoMessage(err)}</Alert>
    </Stack>
  )
}

function RenderNodata () {
  const { t } = useTranslation()
  return (
    <Stack sx={{ width: '100%' }}>
      <Alert severity="info">{t('cards.no-record')}</Alert>
    </Stack>
  )
}

function RenderLoading () {
  return (
    <Box sx={{ p: 1 }}>
      <LinearProgress />
      {false && <CircularProgress />}
    </Box>
  )
}

export function Cards () {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  // データ取得
  const {
    data,
    isValidating,
    errorInfo,
    size,
    setSize,
    mutate
  } = useCreditCards()

  let dataLength = 0
  let pageInfo: PageInfo | undefined
  if (data && data.length > 0) {
    const lastOne = data[data.length - 1]
    if (lastOne) {
      const connection = lastOne.user.creditCards
      pageInfo = connection.pageInfo
    }
    data.forEach(it => {
      dataLength += it.user.creditCards.edges.length
    })
  }
  const hasMoreData = (
    !isValidating &&
    !errorInfo &&
    pageInfo != null &&
    pageInfo.hasNextPage
  )
  const fetchMoreData = () => {
    setTimeout(() => {
      setSize(size + 1)
    }, 1500)
  }

  useEffect(() => {
    if (errorInfo) {
      dispatch(actions.push({
        source: 'app',
        code: errorInfo.code,
        err: errorInfo.err
      }))
    }
  }, [errorInfo])

  function renderEndMessage () {
    // fetching
    if (dataLength <= 0 && isValidating) return <RenderLoading />
    // error
    if (dataLength <= 0 && errorInfo) return <RenderError err={errorInfo} />
    // no data
    if (dataLength <= 0) return <RenderNodata />
    return <></>
  }

  const [addedItem, setAddedItem] = useState<CreditCard | undefined>()
  const [deletingItem, setDeletingItem] = useState<CreditCard | undefined>()
  const {
    isValidating: isValidatingDelete,
    errorInfo: errorInfoDelete,
    data: dataDelete,
    mutate: mutateDelete,
    reset: resetDelete
  } = useDeleteCard()

  useEffect(() => {
    if (errorInfoDelete) {
      dispatch(actions.push({
        source: 'app',
        code: errorInfoDelete.code,
        err: errorInfoDelete.err
      }))
    }
  }, [errorInfoDelete])

  useEffect(() => {
    if (!isValidatingDelete && dataDelete) {
      mutate().catch(_ => {})
    }
    if (addedItem) {
      mutate().catch(_ => {})
    }
  }, [isValidatingDelete, dataDelete, addedItem])

  const divRef = useRef<HTMLDivElement | null>(null)
  const [contentHeight, setContentHeight] = useState(200)
  const layout = useLayout()

  useLayoutEffect(() => {
    function updateSize () {
      const main = layout.mainRef()
      const div = divRef.current
      if (main && div) {
        const h = main.offsetTop + main.clientHeight - div.offsetTop - 16
        setContentHeight(h)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [isValidatingDelete, dataDelete, addedItem])

  const [alertMessage, setAlertMessage] = React.useState<string | undefined>(undefined)
  const [registCardPage, setRegistCardPage] = React.useState<Window | null>(null)

  const handleRegisterCard = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    const i = location.href.lastIndexOf('/')
    const forder = location.href.substring(0, i)
    const callbackUrl = forder + '/cards/regist-result'

    CreditRegistUrl(callbackUrl).then((url) => {
      if (url) {
        // window.location.href = url
        const page = window.open(url, '_blank')
        setRegistCardPage(page)
      } else {
        setAlertMessage(t('cards.regist-no-url'))
      }
    }).catch(() => {
      setAlertMessage(t('cards.regist-error-url'))
    })
  }

  useEffect(() => {
    if (registCardPage && registCardPage.closed) {
      mutate().catch(_ => {})
      setRegistCardPage(null)
    }
  }, [registCardPage, registCardPage?.closed])

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* 登録成功 */}
        {(addedItem) &&
          <Stack sx={{ width: '100%', mb: 2 }}>
            <Alert severity="success" onClose={() => setAddedItem(undefined) }>
              {t('cards.success-register')}
            </Alert>
          </Stack>
        }

        {/* 削除成功 */}
        {(!isValidatingDelete && dataDelete) &&
          <Stack sx={{ width: '100%', mb: 2 }}>
            <Alert severity="success" onClose={() => resetDelete()}>
              {t('cards.success-delete')}
            </Alert>
          </Stack>
        }

        {/* エラー表示 */}
        {(alertMessage) &&
          <Stack sx={{ width: '100%', mb: 2 }}>
            <Alert severity="error" onClose={() => setAlertMessage(undefined)}>
              {alertMessage}
            </Alert>
          </Stack>
        }

        {/* 一覧表示 */}
        <div ref={divRef} style={{ width: '100%', position: 'relative' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMoreData}
                height={contentHeight}
                loader={<RenderLoading />}
                endMessage={renderEndMessage()}
                refreshFunction={() => {
                  // refresh
                  mutate().catch(_ => {})
                }}
                pullDownToRefresh
                pullDownToRefreshThreshold={100}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8595; {t('cards.pull-down-refresh')}</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8593; {t('cards.release-refresh')}</h3>
                }
              >
                {(data || []).map((result, i) => {
                  return result.user.creditCards.edges.map((it, ii) => (
                    <RenderItem key={`${i}-${ii}`} item={it.node}
                      onDelete={() => { setDeletingItem(it.node) }}
                    />
                  ))
                })}
              </InfiniteScroll>

            </Grid>
          </Grid>

          <Fab
            color="primary" aria-label="add"
            sx={{ position: 'absolute', bottom: 8, right: 8 }}
            onClick={handleRegisterCard}
          >
            <AddIcon />
          </Fab>
        </div>
      </Box>

      {/* パスワード認証(カード削除) */}
      <ReauthDialog
        open={deletingItem != null}
        onClose={() => setDeletingItem(undefined)}
        onAccept={() => {
          mutateDelete({ item: deletingItem! }).catch(_ => { })
        }}
      />

      <LoadingProgressSimple open={isValidatingDelete} />
    </>
  )
}

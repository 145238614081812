import { Amplify, Auth } from 'aws-amplify'
import { User } from 'firebase/auth'
import { graphqlEndpoint } from './apis/private'

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.AWS_REGION
  },
  API: {
    // REST API
    // endpoints: [
    //   {
    //     name: 'UserFrontAPI',
    //     endpoint: process.env.AWS_USERFRONTAPI_ENDPOINT,
    //     region: process.env.AWS_REGION
    //   },
    //   {
    //     name: 'MyAPIGatewayAPI',
    //     endpoint: process.env.AWS_MYAPIGATEWAY_ENDPOINT,
    //     region: process.env.AWS_REGION
    //   }
    // ],

    // GraphQL API
    graphql_endpoint: graphqlEndpoint,
    graphql_endpoint_iam_region: process.env.AWS_REGION
  }
})

const refreshHandler = (user: User) => async () => {
  const tokenResult = await user.getIdTokenResult(true)
  const token = tokenResult.token
  const expiresAt = parseInt(tokenResult.claims.exp!)
  return {
    token,
    expires_at: expiresAt
  }
}

export async function federatedSignIn (user: User) {
  // token 取得
  const tokenResult = await user.getIdTokenResult()

  // signin パラメータ
  const domain = process.env.COGNITO_FEDERATION_DOMAIN!
  const token = tokenResult.token
  const expiresAt = parseInt(tokenResult.claims.exp!)
  const userInfo = {
    name: user.displayName ?? '',
    email: user.email!
  }

  // warning が出るので一度サインアウトを呼ぶ
  await Auth.signOut()

  // identity pool にsignin
  const cred = await Auth.federatedSignIn(
    domain,
    {
      token,
      expires_at: expiresAt * 1000
    },
    userInfo
  )

  // register handler
  Auth.configure({
    refreshHandlers: {
      [domain]: refreshHandler(user)
    }
  })

  return cred
}

export async function authSignOut () {
  await Auth.signOut()
}

export function getCurrentCredentials () {
  return Auth.currentCredentials()
}

// export async function fetchHelloIAM () {
//   const apiName = 'MyAPIGatewayAPI'
//   const path = '/Prod/hello'
//   const myInit = { // OPTIONAL
//     headers: {}, // OPTIONAL
//     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
//     queryStringParameters: { // OPTIONAL
//       name: 'param'
//     }
//   }
//   const response = await API.get(apiName, path, myInit)
//   return response
// }

// export async function fetchHelloIAM2 (name: string) {
//   const apiName = 'UserFrontAPI'
//   const path = `/dev/api/hello/${name}`
//   const myInit = { // OPTIONAL
//     headers: {}, // OPTIONAL
//     response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
//   }
//   const response = await API.get(apiName, path, myInit)
//   return response
// }

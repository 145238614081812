import { sendPasswordResetEmail } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { parseError } from '~src/models/apis/util'
import { auth } from '~src/models/firebase'

type PasswordResetMutateInput = {
  email: string
}

export function usePasswordReset () {
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState(false)
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: PasswordResetMutateInput) => {
    // 開始
    setResult(false)
    setError(undefined)
    setIsValidating(true)

    // firebase send password reset email
    try {
      await sendPasswordResetEmail(auth, input.email)
      setResult(true)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setIsValidating(false)
    return true
  }, [])

  const reset = useCallback(() => {
    setResult(false)
    setError(undefined)
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'

interface RouterState {
  navigate: NavigateFunction
}

const initialState: RouterState = {
  navigate: () => { }
}

const sliceName = 'router'

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    bind: (state, action: PayloadAction<{ navigate: NavigateFunction }>) => {
      const { navigate } = action.payload
      state.navigate = navigate
    }
  }
})

// Actions
export const actions = slice.actions

// Reducer
export default slice.reducer

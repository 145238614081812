import useSWRInfinite from 'swr/infinite'

// src
import { fetchTransitUsages, FetchTransitUsagesInput } from '~src/models/apis/private'
import { parseError } from '~src/models/apis/util'
import { useRootSelector } from '~src/stores'
import { useKeep } from './keep'

// fetchTransitUsages
export function useTransitUsages (from: string, to: string, last: number) {
  // user id
  const user = useRootSelector(state => state.auth.user)
  const userId = user?.uid ?? ''

  // fetch
  const { error, isValidating, ...restProps } = useSWRInfinite(
    (index, previousData) => {
      const input: FetchTransitUsagesInput = { from, to, last }
      const prevConnection = previousData?.user?.transitUsages
      if (prevConnection) {
        input.before = prevConnection.pageInfo.startCursor
      }
      return [userId, input]
    },
    fetchTransitUsages,
    {
      dedupingInterval: 60000,
      shouldRetryOnError: false
    })

  // isValidating
  const isValidatingKeep = useKeep(isValidating, 1500)

  // data
  return {
    isValidating: isValidatingKeep,
    errorInfo: parseError(error),
    error,
    ...restProps
  }
}

import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// mui
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// src
import { EmailField } from '~src/components/email-field'
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { PasswordField } from '~src/components/password-field'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useConfirmPasswordReset } from '~src/hooks/auth-action'
import { validatePassword, ValidationResult } from '~src/models/validations'

type ConfirmPasswordResetReturnType = ReturnType<typeof useConfirmPasswordReset>
type ConfirmPasswordResetMutateInput = Parameters<ConfirmPasswordResetReturnType['mutate']>[0]
export type AuthConfirmPasswordResetProps = Omit<ConfirmPasswordResetMutateInput, 'newPassword'>

export function AuthConfirmPasswordReset (props: AuthConfirmPasswordResetProps) {
  const { t } = useTranslation()

  const {
    isValidating,
    data,
    errorInfo: err,
    mutate
  } = useConfirmPasswordReset()
  const passwordResetSucceeded = (!isValidating && data)

  const [passwordText, setPasswordText] = useState<string>()
  const [passwordConfirmText, setPasswordConfirmText] = useState<string>()
  const [passwordVR, setPasswordVR] = useState<ValidationResult>()
  const [passwordConfirmVR, setPasswordConfirmVR] = useState<ValidationResult>()

  const submitNotAllowed = (
    (passwordVR?.invalid ?? true) ||
    (passwordConfirmVR?.invalid ?? true) ||
    passwordResetSucceeded
  )

  // パスワードを検証
  const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordText(text)
    setPasswordVR(validatePassword(text))
    if (passwordConfirmText) {
      setPasswordConfirmVR(validatePasswordConfirm(text, passwordConfirmText))
    }
  }

  // パスワード（確認）検証
  const validatePasswordConfirm = (password: string, passwordConfirm: string) => {
    const v: ValidationResult = {
      invalid: false
    }
    if (password !== passwordConfirm) {
      v.invalid = true
      v.tid = 'validation.password-is-not-same'
    }
    return v
  }
  const handlePasswordConfirmChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordConfirmText(text)
    setPasswordConfirmVR(validatePasswordConfirm(passwordText ?? '', text))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const newPassword = data.get('password') as string

    const input: ConfirmPasswordResetMutateInput = {
      ...props,
      newPassword
    }
    mutate(input).catch(_ => { })
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('confirm-password-reset.title')}
        </Typography>

        {/* 成功表示: resetPassword */}
        {(passwordResetSucceeded) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="success">
              {t('confirm-password-reset.success')}
            </Alert>
          </Stack>
        }

        {/* エラー表示: resetPassword */}
        {(!isValidating && err) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="error">
              {ErrorInfoMessage(err)}
            </Alert>
          </Stack>
        }

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <EmailField
                id="confirm-password-reset-email"
                name="email"
                label={t('confirm-password-reset.input-email')}
                readonly={true}
                defaultValue={props.accountEmail}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordField
                id="confirm-password-reset-password"
                name="password"
                label={t('confirm-password-reset.input-password')}
                autoComplete="new-password"
                validationResult={passwordVR}
                onChange={handlePasswordChanged}
                readonly={passwordResetSucceeded}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordField
                id="confirm-password-reset-password-confirm"
                name="password-confirm"
                label={t('confirm-password-reset.input-password-confirm')}
                autoComplete="new-password"
                validationResult={passwordConfirmVR}
                onChange={handlePasswordConfirmChanged}
                readonly={passwordResetSucceeded}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitNotAllowed}
          >
            {t('confirm-password-reset.submit')}
          </Button>

          {(!isValidating && data) &&
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 3 }}
              component={Link}
              to="/"
            >
              {t('confirm-password-reset.back-to-home')}
            </Button>
          }

        </Box>
      </Box>

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}

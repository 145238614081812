import { FirebaseError } from 'firebase/app'
import {
  ActionCodeSettings,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  updatePassword,
  User as AuthUser,
  UserCredential
} from 'firebase/auth'
import log from 'loglevel'
import { call, put, select, takeLatest } from 'redux-saga/effects'

// src
import { addUser, AddUserResult } from '~/src/models/apis/private'
import { auth } from '~/src/models/firebase'
import { RootState } from '~/src/stores'
import { actions as authActions } from '~/src/stores/slices/auth'
import { actions as signupActions } from '~/src/stores/slices/signup'

const publicSignupVerifyURL = process.env.PUBLIC_URL_FOR_SIGNUP_VERIFY!

// Eメールリンク送信のリクエストを処理する
function * handleRequestSendEmailLink (action: ReturnType<typeof signupActions.sendEmailLink>) {
  try {
    const { email } = action.payload

    // TODO :: 登録済みのメールアドレスかチェックする

    // local storage に保持して、その端末でのみ、サインアップできるようにする
    window.localStorage.setItem('emailToSignUp', email)

    // Eメールリンクを送信する
    const actionCodeSettings: ActionCodeSettings = {
      url: publicSignupVerifyURL,
      handleCodeInApp: true
    }
    yield call(sendSignInLinkToEmail, auth, email, actionCodeSettings)

    // 送信成功
    yield put(signupActions.sendingEmailLinkIsSuccessful())
  } catch (err) {
    // 送信エラー
    if (err instanceof FirebaseError) {
      yield put(signupActions.sendingEmailLinkIsFailed({ code: err.code, err }))
    } else {
      yield put(signupActions.sendingEmailLinkIsFailed({ code: 'signup-send-email-link-error', err }))
    }
  }
}

// Eメールリンクによるサインインのリクエストを処理する
function * handleRequestSignInWithEmailLink (action: ReturnType<typeof signupActions.signInWithEmailLink>) {
  try {
    // local storage に保持して、その端末でのみ、サインアップできるようにする
    const email = window.localStorage.getItem('emailToSignUp')
    if (email == null) {
      yield put(signupActions.signingEmailLinkIsFailed({ code: 'signup-email-not-found' }))
      return
    }

    // Eメールリンクかどうかチェック
    const href = window.location.href
    if (!isSignInWithEmailLink(auth, href)) {
      yield put(signupActions.signingEmailLinkIsFailed({ code: 'signup-email-link-invalid' }))
      return
    }

    // Eメールリンクでサインインする
    const userCredential: UserCredential = yield call(signInWithEmailLink, auth, email, href)
    window.localStorage.removeItem('emailToSignUp')
    log.debug('success signup', userCredential)

    // サインイン成功
    yield put(signupActions.signingEmailLinkIsSuccessful())
  } catch (err) {
    // サインインエラー
    if (err instanceof FirebaseError) {
      yield put(signupActions.signingEmailLinkIsFailed({ code: err.code, err }))
    } else {
      yield put(signupActions.signingEmailLinkIsFailed({ code: 'signup-signing-email-link-error', err }))
    }
  }
}

// アカウント登録完了のリクエストを処理する
function * handleRequestCompleteSignup (action: ReturnType<typeof signupActions.completeSignup>) {
  const { input } = action.payload
  const { email, password, ...restInput } = input

  const user: AuthUser | undefined = yield select((state: RootState) => state.auth.user)
  if (user == null) {
    yield put(signupActions.signupError({ code: 'signup-auth-state-invalid' }))
    return
  }

  // リロード
  try {
    yield call(() => user.reload)
    log.debug('success reloading', user)
  } catch (err) {
    const e: FirebaseError = err
    yield put(signupActions.signupError({ code: e.code, err }))
    return
  }

  // パスワードを設定する
  try {
    yield call(updatePassword, user, password)
    log.debug('success set password', user)
  } catch (err) {
    const e: FirebaseError = err
    yield put(signupActions.signupError({ code: e.code, err }))

    // TODO ログイン後、一定時間すぎるとエラーになる
    yield call(signOut, auth)
    return
  }

  // アカウント登録
  try {
    const result: AddUserResult = yield call(addUser, { ...restInput, email })
    yield put(authActions.userAccountFetched({ account: result.addUser.user }))
  } catch (err) {
    yield put(signupActions.signupError({ code: 'signup-add-user-error', err }))
  }
}

export default function * signupSaga () {
  yield takeLatest(signupActions.sendEmailLink, handleRequestSendEmailLink)
  yield takeLatest(signupActions.signInWithEmailLink, handleRequestSignInWithEmailLink)
  yield takeLatest(signupActions.completeSignup, handleRequestCompleteSignup)
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { auth } from '~src/models/firebase'
import log from '~src/models/log'

import translationsEn from './en.json'
import translationsJa from './ja.json'

// 言語設定
let defaultLng = 'ja'
try {
  const lang = window.localStorage.getItem('i18nLng')
  if (lang != null) {
    defaultLng = lang.toLowerCase()
  }
} catch { }

// 多言語対応
i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: defaultLng,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: translationsEn
      },
      ja: {
        translation: translationsJa
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export function changeLanguage (lng: string) {
  log.debug('i18n.changeLanguage', lng)
  i18n
    .changeLanguage(lng)
    .then(() => {
      // 設定保持
      window.localStorage.setItem('i18nLng', lng)
    })
    .catch(_ => { })
}

export function applyLanguage (lng: string) {
  log.debug('apply language', lng)
  // html document
  // document.documentElement.setAttribute('lang', lng)
  document.title = i18n.t('app.title')
  // firebase authentication
  auth.languageCode = lng
}

export default i18n

import * as CryptoJS from 'crypto-js'
import { getCurrentCredentials } from '~src/models/amplify'

type Params = {
  callback: string,
}

type Credentials = {
  accessKeyId: string,
  secretAccessKey: string,
  token: string,
  region: string,
}

const siteUrl = process.env.CREDIT_SITE_URL

const request = {
  url: siteUrl,
  method: 'GET'
}

function GetHost (url: string) {
  const a = document.createElement('a')
  a.href = url
  return a.host
}

function GetPath (url: string) {
  const a = document.createElement('a')
  a.href = url
  return (a.pathname.charAt(0) !== '/' ? '/' + a.pathname : a.pathname)
}

function CanonicalHeaderNames (headers: {}) {
  let ret = ''
  Object.keys(headers).sort().forEach((k) => {
    if (ret.length > 0) ret += ';'
    ret += k.toLowerCase()
  })
  return ret
}

function CanonicalHeaders (headers: {[key: string]: string}) {
  let ret = ''
  if (headers) {
    Object.keys(headers).sort().forEach((name) => {
      ret += name.toLowerCase() + ':' + encodeURIComponent(headers[name]) + '\n'
    })
  }
  return ret
}

function SerialParameters (queryParameterObj: {[key: string]: string}) {
  const pieces :string[] = []
  if (queryParameterObj) {
    Object.keys(queryParameterObj).sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    }).forEach((k) => {
      pieces.push(k + '=' + encodeURIComponent(queryParameterObj[k]))
    })
  }
  return pieces.length > 0 ? pieces.join('&') : ''
}

function CanonicalRequest (path: string, parameters: string, canonicalHeaderNames: string, canonicalHeaders: string, bodyhash: string) {
  let ret = ''
  ret += 'GET\n'
  ret += path + '\n'
  ret += parameters + '\n'
  ret += canonicalHeaders + '\n'
  ret += canonicalHeaderNames + '\n'
  ret += bodyhash
  return ret
}

function Signature (toSign: string, date: Date, credentials: Credentials) {
  const kDate = Hmac('AWS4' + credentials.secretAccessKey, AmzShortDate(date))
  const kRegion = Hmac(kDate, credentials.region as string)
  const kService = Hmac(kRegion, 'execute-api')
  const kSigning = Hmac(kService, 'aws4_request')
  const signature = Hmac(kSigning, toSign)

  return signature.toString()
}

function HashString (str: string | CryptoJS.lib.WordArray) {
  return CryptoJS.SHA256(str).toString()
}

function Hmac (key: string | CryptoJS.lib.WordArray, data: string | CryptoJS.lib.WordArray) {
  return CryptoJS.HmacSHA256(data, key)
}

function AmzShortDate (date: Date) {
  return AmzLongDate(date).substring(0, 8)
}

function AmzLongDate (date: Date) {
  return date.toISOString().replace(/[:/-]|\.\d{3}/g, '').substring(0, 17)
}

function GetQuery (params: Params, credentials: Credentials) {
  const _date = new Date()

  const headers: {[key: string]: string} = {}
  headers.host = GetHost(request.url as string)

  const canonicalHeaderNames = CanonicalHeaderNames(headers)
  const canonicalHeaders = CanonicalHeaders(headers)
  const _params: {[key: string]: string} = (!params) ? {} : params

  //  query parameter あればココ
  _params['X-Amz-Algorithm'] = 'AWS4-HMAC-SHA256'
  _params['X-Amz-Credential'] = credentials.accessKeyId + '/' + AmzShortDate(_date) + '/' + credentials.region + '/execute-api/aws4_request'
  _params['X-Amz-Date'] = AmzLongDate(_date)
  _params['X-Amz-SignedHeaders'] = canonicalHeaderNames
  if (credentials.token) {
    _params['X-Amz-Security-Token'] = credentials.token
  }

  const parameters = SerialParameters(_params)
  const path = GetPath(request.url as string)
  const bodyhash = HashString('') // bodyは無い

  // 署名バージョン４の正規リクエストを作成する
  const canonical = CanonicalRequest(path, parameters, canonicalHeaderNames, canonicalHeaders, bodyhash)
  const canonicalHash = HashString(canonical)
  const sign = 'AWS4-HMAC-SHA256' + '\n' + AmzLongDate(_date) + '\n' + AmzShortDate(_date) + '/' + credentials.region + '/execute-api/aws4_request' + '\n' + canonicalHash

  // 署名の作成
  const signature = Signature(sign, _date, credentials)

  // クエリストリング作成
  _params['X-Amz-Signature'] = signature
  let query = ''
  for (const key in _params) { // 生成したパラメータを結合
    if (query.length > 0) query += '&'
    query += key + '=' + encodeURIComponent(_params[key])
  }

  return query
}

export function CreditRegistUrl (callbackUrl: string) {
  return getCurrentCredentials().then((cred) => {
    const query = GetQuery(
      {
        callback: callbackUrl
      },
      {
        accessKeyId: cred.accessKeyId as string,
        secretAccessKey: cred.secretAccessKey as string,
        token: cred.sessionToken as string,
        region: process.env.CREDIT_REGION as string
      }
    )
    return siteUrl + '?' + query
  }).catch(() => {
    return null
  })
}

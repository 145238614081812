import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

// icons
import FileOpen from '@mui/icons-material/FileOpen'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

// src
import { ValidationResult } from '~/src/models/validations'
import { EmailField } from '~src/components/email-field'
import { useAppDispatch } from '~src/stores'
import { actions } from '~src/stores/slices/signup'

export type SignUpNewProps = {
  onContinue?: () => void,
  children?: React.ReactNode
}

export function SignUpNew (props: SignUpNewProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [emailVR, setEmailVR] = useState<ValidationResult>()

  const submitNotAllowed = (
    emailVR?.invalid ?? true
  )

  const handleEmailValidationResultChanged = (value: ValidationResult) => {
    setEmailVR(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string
    dispatch(actions.sendEmailLink({ email }))
    navigate('../sending')
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* ロゴアイコン TBD :: 後で変更 */}
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('sign-up.title')}
        </Typography>

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <EmailField
                id="sign-up-email"
                name="email"
                label={t('sign-up.input-email')}
                onValidationResultChanged={handleEmailValidationResultChanged}
              />
            </Grid>

            {/* サービス利用規約 */}
            <Grid item xs={12}>
              <Link
                color="inherit"
                target="_blank"
                rel="noopener"
                href={t('agreement.goto-terms-of-service-url')}
              >
                <FileOpen sx={{ mr: 1 }} />
                {t('agreement.goto-terms-of-service')}
              </Link>
            </Grid>

            {/* 個人情報保護方針 */}
            <Grid item xs={12}>
              <Link
                color="inherit"
                target="_blank"
                rel="noopener"
                href={t('agreement.goto-privacy-policy-url')}
              >
                <FileOpen sx={{ mr: 1 }} />
                {t('agreement.goto-privacy-policy')}
              </Link>
            </Grid>

          </Grid>

          {/* 説明 */}
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            {t('agreement.description')}
          </Typography>

          {/* TODO :: reCaptcha V2 を入れる */}

          {/* 登録 */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={submitNotAllowed}
          >
            {t('agreement.submit')}
          </Button>

          {/* リンク: サインイン */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} variant="body2" to="../../login">
                {t('sign-up.goto-sign-in')}
              </Link>
            </Grid>
          </Grid>

        </Box>

      </Box>
    </>
  )
}

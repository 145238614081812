import * as React from 'react'
import { createContext, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

// mui
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// icons
import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'
import Translate from '@mui/icons-material/Translate'

// ./
import { Copyright } from '../copyright'
import { useThemeHandler } from '../theme'

// レイアウトコンテキスト
type LayoutObject = {
  mainRef(): HTMLElement | undefined
}
const LayoutContext = createContext<LayoutObject>({
  mainRef: () => undefined
})
export function useLayout () {
  return useContext(LayoutContext)
}

// 共通レイアウト
export type LayoutProps = {
  title?: string
  footerComponent?: React.ElementType
  children?: React.ReactNode
}
export function Layout (props: LayoutProps) {
  const theme = useTheme()
  const { toggle: toggleColor } = useThemeHandler()
  const [searchParams, setSearchParams] = useSearchParams()

  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleMenuSelect = (selected: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(null)

    searchParams.set('lang', selected)
    setSearchParams(searchParams)
  }

  const divRef = useRef<HTMLDivElement | null>(null)
  const layoutObject: LayoutObject = useMemo(() => {
    return {
      mainRef () {
        return divRef.current?.parentElement ?? undefined
      }
    }
  }, [])

  const title = props.title ?? t('app.title')
  return (
    <LayoutContext.Provider value={layoutObject}>
      {/* 共通ヘッダー */}
      <AppBar position='static' color="primary" enableColorOnDark>
        <Toolbar>
          {/* タイトルバー */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          {/* ダークモード */}
          <IconButton
            aria-label="color"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleColor}
            color="inherit">
            {theme.palette.mode === 'dark' ? <DarkMode /> : <LightMode />}
          </IconButton>

          {/* 言語選択 */}
          <IconButton
            aria-label="translation"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit">
            <Translate />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            keepMounted
          >
            <MenuItem onClick={handleMenuSelect('en')}>{t('app.lang-en')}</MenuItem>
            <MenuItem onClick={handleMenuSelect('ja')}>{t('app.lang-ja')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {/* <Toolbar /> */}

      {/* コンテンツ */}
      <Container component="main" maxWidth="xs" fixed sx={{ flex: 1, overflow: 'auto' }}>
        <div ref={divRef}>
          {props.children}
        </div>
      </Container>

      {/* 共通フッター */}
      <AppBar position='static' component="footer" color="primary">
        {(props.footerComponent)
          ? <props.footerComponent />
          : <Copyright sx={{ mt: 1, mb: 1 }} />
        }
      </AppBar>
    </LayoutContext.Provider>
  )
}

import { applyActionCode, checkActionCode, confirmPasswordReset, isSignInWithEmailLink, signInWithEmailAndPassword, signInWithEmailLink, verifyPasswordResetCode } from 'firebase/auth'
import log from 'loglevel'
import { useCallback, useState } from 'react'
import { parseError } from '~src/models/apis/util'
import { auth } from '~src/models/firebase'

type AuthActionMutateInput = {
  mode: string
  actionCode: string
  continueUrl?: string
  lang?: string
}

type AuthActionMutateResult = {
  mode: 'signIn' | 'resetPassword' | 'recoverEmail' | 'verifyEmail'
  continueUrl?: string
  signIn?: {
    isSignUp: boolean
  }
  resetPassword?: {
    accountEmail: string
  }
  recoverEmail?: {
    restoredEmail: string
  }
}

type ErrorWithCode = {
  code?: string
} & Error

export function useAuthAction () {
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState<AuthActionMutateResult | undefined>()
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: AuthActionMutateInput) => {
    // 開始
    setResult(undefined)
    setError(undefined)
    setIsValidating(true)
    log.debug('useAuthAction - start', input)

    // mode
    switch (input.mode) {
      case 'signIn':
        // メールリンク認証
        try {
          let email = window.localStorage.getItem('emailToSignUp')
          let signup = true
          if (email == null) {
            email = window.localStorage.getItem('emailToSignIn')
            signup = false
          }
          if (email == null) {
            // error
            const err: ErrorWithCode = new Error('Signin email is not found on this device.')
            err.code = 'signin-email-not-found'
            setError(err)
            setIsValidating(false)
            log.error('useAuthAction - error', err)
            throw err
          }
          // Eメールリンクを検証する
          const href = window.location.href
          if (!isSignInWithEmailLink(auth, href)) {
            // error
            const err: ErrorWithCode = new Error('Signup email link is invalid.')
            err.code = 'signin-email-link-invalid'
            setError(err)
            setIsValidating(false)
            log.error('useAuthAction - error', err, href)
            throw err
          }
          // Eメールリンクでサインインする
          const cred = await signInWithEmailLink(auth, email, href)
          window.localStorage.removeItem(signup ? 'emailToSignUp' : 'emailToSignIn')
          // サインイン
          const result: AuthActionMutateResult = {
            mode: input.mode,
            continueUrl: input.continueUrl,
            signIn: {
              isSignUp: signup
            }
          }
          setResult(result)
          setIsValidating(false)
          log.debug('useAuthAction - success', result, cred)
          return result
        } catch (err) {
          // error
          const e: ErrorWithCode = err
          if (e.code == null) {
            e.code = 'signup-signing-email-link-error'
          }
          setError(e)
          setIsValidating(false)
          log.error('useAuthAction - error', err)
          throw err
        }

      case 'resetPassword':
        // パスワードのリセット
        try {
          // アクションコードを検証する
          const accountEmail = await verifyPasswordResetCode(auth, input.actionCode)
          // この後confirmPasswordResetを呼ぶ必要がある
          const result:AuthActionMutateResult = {
            mode: input.mode,
            continueUrl: input.continueUrl,
            resetPassword: { accountEmail }
          }
          setResult(result)
          setIsValidating(false)
          return result
        } catch (err) {
          // error
          setError(err)
          setIsValidating(false)
          throw err
        }

      case 'recoverEmail':
        // メールアドレス変更の取り消し
        try {
          const info = await checkActionCode(auth, input.actionCode)
          const restoredEmail = info.data.email!
          await applyActionCode(auth, input.actionCode)
          const result:AuthActionMutateResult = {
            mode: input.mode,
            continueUrl: input.continueUrl,
            recoverEmail: {
              restoredEmail
            }
          }
          setResult(result)
          setIsValidating(false)
          return result
        } catch (err) {
          setError(err)
          setIsValidating(false)
          throw err
        }

      case 'verifyEmail':
        // メールアドレスの検証
        try {
          await applyActionCode(auth, input.actionCode)
          const result:AuthActionMutateResult = {
            mode: input.mode,
            continueUrl: input.continueUrl
          }
          setResult(result)
          setIsValidating(false)
          return result
        } catch (err) {
          setError(err)
          setIsValidating(false)
          throw err
        }

      default: {
        // Error: invalid mode
        const err = new Error(`Specified mode "${input.mode}" is unrecognized: invalid mode`)
        setError(err)
        setIsValidating(false)
        throw err
      }
    }
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate
  }
}

type ConfirmPasswordResetMutateInput = {
  accountEmail: string
  newPassword: string
  actionCode: string
  continueUrl?: string
  lang?: string
}

export function useConfirmPasswordReset () {
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState(false)
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: ConfirmPasswordResetMutateInput) => {
    // 開始
    setResult(false)
    setError(undefined)
    setIsValidating(true)

    try {
      // firebase confirm password reset
      await confirmPasswordReset(auth, input.actionCode, input.newPassword)

      // TODO :: sign in directly or link back to continueUrl

      // directly signin with email & password
      await signInWithEmailAndPassword(auth, input.accountEmail, input.newPassword)

      setResult(true)
      setIsValidating(false)
      return true
    } catch (err) {
      // error
      setError(err)
      setIsValidating(false)
      throw err
    }
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate
  }
}

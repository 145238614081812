import log, { Logger, LogLevelNumbers } from 'loglevel'

// Writing plugins:
// https://www.npmjs.com/package/loglevel
const originalMethodFactory = log.methodFactory
function methodFactory (methodName: string, level: LogLevelNumbers, loggerName: string | symbol) {
  const rawMethod = originalMethodFactory(methodName, level, loggerName)
  return rawMethod.bind(undefined, `[${methodName}] userfront:`)

  // return (...message: any[]) => {
  //   rawMethod.apply(console, [`[${methodName}] userfront:`, ...message])
  // }
}
log.methodFactory = methodFactory

// Log levels:
function setLogLevel (logger: Logger, level: string) {
  level = level.toLowerCase()
  switch (level) {
    case 'trace':
    case 'debug':
    case 'info':
    case 'warn':
    case 'error':
    case 'silent':
      logger.setLevel(level)
      break
  }
}
setLogLevel(log, process.env.LOG_LEVEL ?? 'info')

// default log (root)
export default log

import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// src
import { EmailField } from '~src/components/email-field'
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { ErrorInfoMessage } from '~src/functionals/error'
import { usePasswordReset } from '~src/hooks/password-reset'
import { ValidationResult } from '~src/models/validations'

// 参考情報:
// - autofill
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill

export function ResetPassword () {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [emailVR, setEmailVR] = useState<ValidationResult>()
  const submitNotAllowed = (emailVR?.invalid ?? true)

  const {
    isValidating,
    data,
    errorInfo: err,
    mutate
  } = usePasswordReset()

  const handleEmailValidationResultChanged = (value: ValidationResult) => {
    setEmailVR(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string | null ?? ''
    mutate({ email }).catch(_ => { })
  }

  const handleBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('reset-password.title')}
        </Typography>

        {/* 成功表示 */}
        {(!isValidating && data) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="success">
              {t('reset-password.success')}
            </Alert>
          </Stack>
        }

        {/* エラー表示 */}
        {(!isValidating && err) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="error">
              {ErrorInfoMessage(err)}
            </Alert>
          </Stack>
        }

        {/* 説明 */}
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('reset-password.description')}
        </Typography>

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EmailField
                id="reset-password-email"
                name="email"
                label={t('reset-password.input-email')}
                onValidationResultChanged={handleEmailValidationResultChanged}
              />
            </Grid>
          </Grid>

          {/* パスワードリセット */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitNotAllowed}
          >
            {t('reset-password.submit')}
          </Button>

          {/* 戻る */}
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={handleBackClick}
          >
            {t('reset-password.back')}
          </Button>
        </Box>

      </Box>

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}

import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

// mui
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// icons
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import SendIcon from '@mui/icons-material/Send'

// src
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useAuthAction } from '~src/hooks/auth-action'
import { AuthConfirmPasswordReset } from './confirm-password-reset'

type RenderSignInMessageProps = {
  state: 'success' | 'failed' | 'loading' | undefined
}

function SignInMessage (props: RenderSignInMessageProps) {
  const { t } = useTranslation()
  const { state } = props
  switch (state) {
    case 'failed':
      return (
        <>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CancelScheduleSendIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('signup-verify.message-failed')}
          </Typography>
        </>
      )

    case 'success':
      return (
        <>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <MarkEmailReadIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('signup-verify.message-signed')}
          </Typography>
        </>
      )

    case 'loading':
      return (
        <>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <SendIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('signup-verify.message-signing')}
          </Typography>
        </>
      )

    default:
      return (
        <></>
      )
  }
}

export function AuthActionCallback () {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const inputMemo = useMemo(() => {
    return {
      mode: searchParams.get('mode') ?? '',
      actionCode: searchParams.get('oobCode') ?? '',
      continueUrl: searchParams.get('continueUrl') ?? undefined,
      lang: searchParams.get('lang') ?? 'en'
    }
  }, [searchParams])

  const {
    isValidating,
    data,
    errorInfo: err,
    mutate
  } = useAuthAction()

  useEffect(() => {
    // 2度呼ばれるのでその対策
    const timer = setTimeout(() => {
      mutate(inputMemo).catch(_ => { })
    }, 300)
    return () => clearTimeout(timer)
  }, [inputMemo])

  const state = useMemo(() => {
    if (!isValidating && data) {
      return 'success'
    }
    if (!isValidating && err) {
      return 'failed'
    }
    if (isValidating) {
      return 'loading'
    }
  }, [isValidating, data, err])

  let continueTo = '/'
  if (data && data.signIn && data.signIn.isSignUp) {
    continueTo = '/signup/complete'
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* サインインリンク */}
        {(inputMemo.mode === 'signIn') &&
          <SignInMessage state={state} />
        }
        {(!isValidating && data && data.mode === 'signIn') &&
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            component={Link}
            to={continueTo}
          >
            {t('signup-verify.button-continue')}
          </Button>
        }

        {/* パスワードリセット */}
        {(!isValidating && data && data.mode === 'resetPassword') &&
          <AuthConfirmPasswordReset
            accountEmail={data.resetPassword!.accountEmail}
            actionCode={inputMemo.actionCode}
            continueUrl={inputMemo.continueUrl}
            lang={inputMemo.lang}
          />
        }

        {/* 成功表示: recoverEmail */}
        {(!isValidating && data && data.mode === 'recoverEmail') &&
          <Stack sx={{ width: '100%' }}>
            <Alert severity="success">
              {t('auth-action.success-recover-email')}
            </Alert>
          </Stack>
        }

        {/* 成功表示: verifyEmail */}
        {(!isValidating && data && data.mode === 'verifyEmail') &&
          <Stack sx={{ width: '100%' }}>
            <Alert severity="success">
              {t('auth-action.success-verify-email')}
            </Alert>
          </Stack>
        }

        {/* エラー表示 */}
        {(!isValidating && err) &&
          <Stack sx={{ width: '100%' }}>
            <Alert severity="error">
              {ErrorInfoMessage(err)}
            </Alert>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 3 }}
              component={Link}
              to="/"
            >
              {t('auth-action.back-to-home')}
            </Button>
          </Stack>
        }

      </Box>

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}

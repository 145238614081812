import { sendEmailVerification, updateEmail } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { updateUser } from '~src/models/apis/private'
import { parseError } from '~src/models/apis/util'
import { useAppDispatch, useRootSelector } from '~src/stores'
import { actions } from '~src/stores/slices/auth'

type ChangeEmailMutateInput = {
  newEmail: string
}

export function useChangeEmail () {
  const dispatch = useAppDispatch()
  const user = useRootSelector(state => state.auth.user)
  const userAccount = useRootSelector(state => state.auth.account)
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState(false)
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: ChangeEmailMutateInput) => {
    // ユーザー認証必須
    if (user == null || userAccount == null) {
      const err: Error & { code?: string } = new Error('Unauthenticated')
      err.code = 'unauthenticated-access'
      setError(err)
      throw err
    }

    // 開始
    setResult(false)
    setError(undefined)
    setIsValidating(true)

    // firebase update email
    try {
      await updateEmail(user, input.newEmail)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // firebase send email verification
    try {
      await sendEmailVerification(user)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // userfront update email
    try {
      // email address を更新する
      const resultUpdate = await updateUser({
        userId: userAccount.id,
        uid: user.uid,
        email: input.newEmail
      })
      // アプリケーション状態を更新
      dispatch(actions.userAccountFetched({ account: resultUpdate.updateUser.user }))
      setResult(true)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setIsValidating(false)
    return true
  }, [])

  const reset = useCallback(() => {
    setResult(false)
    setError(undefined)
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

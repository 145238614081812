import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import log from 'loglevel'

import { actions as authActions } from './auth'
import { actions as signupActions } from './signup'

export type ErrorSource =
  'signup' |
  'auth' |
  'app'

export type ErrorDetail = {
  source: ErrorSource
  code: string
  message: string
}

interface ErrorState {
  queue: ErrorDetail[]
}

const initialState: ErrorState = {
  queue: []
}

const sliceName = 'error'

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    push: (state, action: PayloadAction<{ source: ErrorSource, code: string, err?: Error }>) => {
      // 末尾に追加
      log.debug(`action - ${sliceName}/push`, action.payload)
      const { source, code, err } = action.payload
      state.queue.push({
        source,
        code,
        message: err?.message ?? '' // TODO 仮
      })
    },
    shift: (state) => {
      // 先頭を削除
      log.debug(`action - ${sliceName}/shift`)
      state.queue.shift()
    },
    clear: (state) => {
      // clear all
      log.debug(`action - ${sliceName}/clear`)
      state.queue = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.authError, (state, action) => {
        const { code, err } = action.payload
        // auth error
        state.queue.push({
          source: 'auth',
          code,
          message: err?.message ?? '' // TODO 仮
        })
      })
      .addMatcher(isAnyOf(
        signupActions.sendingEmailLinkIsFailed,
        signupActions.signingEmailLinkIsFailed,
        signupActions.signupError
      ), (state, action) => {
        const { code, err } = action.payload
        // signup error
        state.queue.push({
          source: 'signup',
          code,
          message: err?.message ?? '' // TODO 仮
        })
      })
  }
})

// Actions
export const actions = slice.actions

// Reducer
export default slice.reducer

import * as React from 'react'

import Link, { LinkProps } from '@mui/material/Link'
import { Link as RouterLink, useLocation } from 'react-router-dom'

type LinkWithQueryProps = {
  to: string,
  children?: React.ReactNode,
} & LinkProps

export function LinkWithQuery (props: LinkWithQueryProps) {
  const { children, to, ...rest } = props
  const { search } = useLocation()
  return (
    <Link component={RouterLink} to={to + search} {...rest}>
      {children}
    </Link>
  )
}

import { useTranslation } from 'react-i18next'

// src
import { ErrorInfo } from '~src/models/apis/util'
import { ErrorDetail } from '~src/stores/slices/error'

export function ErrorInfoMessage (error: ErrorInfo | undefined) {
  const { t } = useTranslation()

  if (error) {
    const errTitle = 'error-message.app.' + error?.code.replace('/', '-')
    const errMessage = t(errTitle, { defaultValue: t('error-message.etc-error') })
    if (errMessage) {
      return errMessage
    }
  }
  return t('error-message.etc-error')
}

export function ErrorDetailMessage (error: ErrorDetail | undefined) {
  const { t } = useTranslation()

  if (error) {
    const errTitle = 'error-message.' + error?.source + '.' + error?.code.replace('/', '-')
    const errMessage = t(errTitle, { defaultValue: t('error-message.etc-error') })
    if (errMessage) {
      return errMessage
    }
  }
  return t('error-message.etc-error')
}

import { PickersLocaleText } from '@mui/x-date-pickers/locales'
import { getPickersLocalization } from '@mui/x-date-pickers/locales/utils/getPickersLocalization'

type CalendarPickerView = 'year' | 'day' | 'month'

const views = {
  hours: '時',
  minutes: '分',
  seconds: '秒'
}

const jaJPPickers: PickersLocaleText<any> = {
  // Calendar navigation
  previousMonth: '先月',
  nextMonth: '来月',

  // View navigation
  openPreviousView: 'open previous view',
  openNextView: 'open next view',
  calendarViewSwitchingButtonAriaLabel: (view: CalendarPickerView) =>
    view === 'year'
      ? 'year view is open, switch to calendar view'
      : 'calendar view is open, switch to year view',

  // DateRange placeholders
  start: '開始',
  end: '終了',

  // Action bar
  cancelButtonLabel: 'キャンセル',
  clearButtonLabel: 'クリア',
  okButtonLabel: 'OK',
  todayButtonLabel: '今日',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Select ${views[view]}. ${
      time === null ? 'No time selected' : `Selected time is ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} 時`,
  minutesClockNumberText: (minutes) => `${minutes} 分`,
  secondsClockNumberText: (seconds) => `${seconds} 秒`,

  // Open picker labels
  openDatePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Choose date, selected date is ${utils.format(utils.date(rawValue)!, 'fullDate')}`
      : 'Choose date',
  openTimePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Choose time, selected time is ${utils.format(utils.date(rawValue)!, 'fullTime')}`
      : 'Choose time',

  // Table labels
  timeTableLabel: 'pick time',
  dateTableLabel: 'pick date'
}

export const jaJP = getPickersLocalization(jaJPPickers)

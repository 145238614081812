import * as React from 'react'

// mui
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

// 参考資料:
// -  rel="noopener", rel="noreferrer" について
//    https://web.dev/external-anchors-use-rel-noopener/

// コピーライト
export function Copyright (props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Link
        color="inherit"
        target="_blank"
        rel="noopener"
        href="https://www.figinc.jp/"
      >
        FIG, Inc.
      </Link>
      {' All rights reserved.'}
    </Typography>
  )
}

import { fork } from 'redux-saga/effects'

// src
import authSaga from './auth'
import signupSaga from './signup'

export default function * rootSaga () {
  yield fork(authSaga)
  yield fork(signupSaga)
}

import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import rootSaga from './sagas/root'
import authReducer from './slices/auth'
import errorReducer from './slices/error'
import loadingReducer from './slices/loading'
import routerReducer from './slices/router'
import signupReducer from './slices/signup'

const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    router: routerReducer,
    signup: signupReducer,
    loading: loadingReducer
  },
  middleware: middlewares => [
    ...middlewares({
      thunk: false,
      serializableCheck: {
        ignoredActions: [
          'error/push',
          'auth/authError',
          'auth/signedIn',
          'router/bind'
        ],
        ignoredPaths: [
          'auth.user',
          'router.navigate'
        ]
      }
    }),
    sagaMiddleware
  ],
  devTools: true
})
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector

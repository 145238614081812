import log from 'loglevel'
import { createContext, useContext } from 'react'

type CardAPIInitCredentials = {
  accessKeyId: string
  secretAccessKey: string
  token?: string
}

type CardAPIInitResult = {
  Result: boolean
  ErrorCd: string
  Ver: number
  Pem: string
}

type CardAPIRegistResult = {
  Result: boolean
  ErrorCd: string
  SystemCardId: string
  IsExists: boolean
}

type CardAPILib = {
  // 初期化
  init(
    credentials: CardAPIInitCredentials,
    onCallback: (status: number, result: CardAPIInitResult) => void,
    onError: (status: number, statusText: string, errorThrown: any) => void
  ): void
  // カード登録
  regist(
    accountNumber: string,
    expiry: string,
    onCallback: (status: number, result: CardAPIRegistResult) => void,
    onError: (status: number, statusText: string, errorThrown: any) => void
  ): void
}

interface Window {
  CardAPI: CardAPILib
}
// eslint-disable-next-line no-var
declare var window: Window & typeof globalThis

export type CardAPIAsyncInitInput = {
  credentials: CardAPIInitCredentials
}

export type CardAPIAsyncInitResult = {
  status: number
} & CardAPIInitResult

export type CardAPIAsyncRegistInput = {
  accountNumber: string
  expiry: string
}

export type CardAPIAsyncRegistResult = {
  status: number
} & CardAPIRegistResult

export class CardAPIAsync {
  private lib () {
    const lib = window.CardAPI
    if (lib == null) {
      const err = new Error('window.CardAPI is null: you need to load js.')
      log.error(err)
      throw err
    }
    return lib
  }

  async init (input: CardAPIAsyncInitInput): Promise<CardAPIAsyncInitResult> {
    const { credentials } = input

    return new Promise((resolve, reject) => {
      try {
        log.debug('card-api - init')
        this.lib().init(credentials,
          (status, result) => {
            log.debug('card-api - init /onCallback', status)

            if (!result.Result) {
              // failure
              const err: Error & { code?: string } = new Error(`init failure: ${result.ErrorCd}`)
              err.code = result.ErrorCd
              reject(err)
              return
            }

            // success
            resolve({
              status,
              ...result
            })
          },
          (status, statusText, errorThrown) => {
            // error
            log.error('card-api - init /onError', status, errorThrown)
            reject(errorThrown)
          })
      } catch (err) {
        log.error('card-api - init /err', err)
        reject(err)
      }
    })
  }

  async regist (input: CardAPIAsyncRegistInput): Promise<CardAPIAsyncRegistResult> {
    const { accountNumber, expiry } = input

    // カード登録
    return new Promise((resolve, reject) => {
      try {
        log.debug('card-api - regist')
        this.lib().regist(accountNumber, expiry,
          (status, result) => {
            log.debug('card-api - regist /onCallback', status)

            if (!result.Result) {
              // failure
              const err: Error & { code?: string } = new Error(`regist failure: ${result.ErrorCd}`)
              err.code = result.ErrorCd
              reject(err)
              return
            }

            // success
            resolve({
              status,
              ...result
            })
          },
          (status, statusText, errorThrown) => {
            // error
            log.error('card-api - regist /onError', status, errorThrown)
            reject(errorThrown)
          })
      } catch (err) {
        log.error('card-api - regist /err', err)
        reject(err)
      }
    })
  }

  maskAccountNumber (accountNumber: string) {
    switch (accountNumber.length) {
      case 14:
        return `****-******-${accountNumber.slice(-4)}`
      case 15:
        return `****-******-*${accountNumber.slice(-4)}`
      case 16:
      default:
        return `****-****-****-${accountNumber.slice(-4)}`
    }
  }
}

const CardAPIContext = createContext<CardAPIAsync>(new CardAPIAsync())

export function useCardAPIClient () {
  return useContext(CardAPIContext)
}

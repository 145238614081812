import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

// ./
import { useRootSelector } from '~/src/stores'
import { LoadingProgressSimple } from '~src/components/loading-progress'

// 認証していない場合は /login にリダイレクト
export function RequireAuth ({ children }: { children?: React.ReactNode }) {
  const location = useLocation()
  const userLoading = useRootSelector(state => state.auth.userState)
  const user = useRootSelector(state => state.auth.user)
  const accountLoading = useRootSelector(state => state.auth.accountState)
  const account = useRootSelector(state => state.auth.account)
  if (userLoading === 'idle') {
    // load直後: まだ状態がわからないので何もしない
    return <></>
  }
  if (!user) {
    // ユーザ認証していない
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  if (accountLoading === 'idle') {
    // まだアカウント情報を読み込んでいない
    return <>
      <LoadingProgressSimple open />
    </>
  }
  if (account == null && location.pathname !== '/signup/complete') {
    // まだアカウント情報が未登録
    return <Navigate to="/signup/complete" replace={true} />
  }
  // ユーザ認証されている
  return <>{children}</>
}

// 認証している場合は /app にリダイレクト
export function SkipSigning ({ children }: { children?: React.ReactNode }) {
  const user = useRootSelector(state => state.auth.user)
  if (user) {
    return <Navigate to="/app" replace />
  }
  return <>{children}</>
}

import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

// src
import { validatePassword, ValidationResult } from '~/src/models/validations'
import { EmailField } from '~src/components/email-field'
import { PasswordField } from '~src/components/password-field'
import { useAppDispatch, useRootSelector } from '~src/stores'
import { actions } from '~src/stores/slices/signup'

export type SignUpCompleteProps = {
  children?: React.ReactNode
}

export function SignUpComplete (props: SignUpCompleteProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useRootSelector(state => state.auth.user)
  const account = useRootSelector(state => state.auth.account)
  const accountState = useRootSelector(state => state.auth.accountState)

  const [invalidUserName, setInvalidUserName] = useState<boolean>()
  const [passwordText, setPasswordText] = useState<string>()
  const [passwordConfirmText, setPasswordConfirmText] = useState<string>()
  const [passwordVR, setPasswordVR] = useState<ValidationResult>()
  const [passwordConfirmVR, setPasswordConfirmVR] = useState<ValidationResult>()

  const submitNotAllowed = (
    invalidUserName == null ||
    invalidUserName ||
    (passwordVR?.invalid ?? true) ||
    (passwordConfirmVR?.invalid ?? true)
  )

  // 名前(UserName)を検証
  const handleUserNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setInvalidUserName(text.trim().length <= 0)
  }

  // パスワードを検証
  const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordText(text)
    setPasswordVR(validatePassword(text))
    if (passwordConfirmText) {
      setPasswordConfirmVR(validatePasswordConfirm(text, passwordConfirmText))
    }
  }

  // パスワード（確認）検証
  const validatePasswordConfirm = (password: string, passwordConfirm: string) => {
    const v: ValidationResult = {
      invalid: false
    }
    if (password !== passwordConfirm) {
      v.invalid = true
      v.tid = 'validation.password-is-not-same'
    }
    return v
  }
  const handlePasswordConfirmChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordConfirmText(text)
    setPasswordConfirmVR(validatePasswordConfirm(passwordText ?? '', text))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)

    const uid = user!.uid
    const email = data.get('email') as string
    const password = data.get('password') as string
    const allowExtraEmails = (data.get('allow-extra-emails') as string) != null
    const userName = data.get('user-name') as string
    const kana = data.get('kana') as string

    dispatch(actions.completeSignup({
      input: {
        uid,
        email,
        password,
        userName,
        kana,
        allowExtraEmails
      }
    }))
  }

  if (accountState !== 'idle' && account != null) {
    // アカウント情報は登録済み
    return <Navigate to="/app" replace={true} />
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* ロゴアイコン TBD :: 後で変更 */}
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('signup-complete.title')}
        </Typography>

        {/* 説明 */}
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          {t('signup-complete.description')}
        </Typography>

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="signup-complete-user-name"
                name="user-name"
                label={t('signup-complete.input-user-name')}
                autoComplete={t('signup-complete.autofill-user-name')}
                error={invalidUserName}
                helperText={invalidUserName ? t('validation.user-name-is-invalid') : undefined}
                onChange={handleUserNameChanged}
                autoFocus
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="signup-complete-kana"
                name="kana"
                label={t('signup-complete.input-kana')}
                autoComplete={t('signup-complete.autofill-kana')}
              />
            </Grid>

            <Grid item xs={12}>
              <EmailField
                id="signup-complete-email"
                name="email"
                label={t('signup-complete.input-email')}
                readonly={true}
                defaultValue={user?.email ?? undefined}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordField
                id="signup-complete-password"
                name="password"
                label={t('signup-complete.input-password')}
                autoComplete="new-password"
                validationResult={passwordVR}
                onChange={handlePasswordChanged}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordField
                id="signup-complete-password-confirm"
                name="password-confirm"
                label={t('signup-complete.input-password-confirm')}
                autoComplete="new-password"
                validationResult={passwordConfirmVR}
                onChange={handlePasswordConfirmChanged}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="signup-complete-allow-extra-emails"
                    name="allow-extra-emails"
                    color="primary"
                    defaultChecked
                  />
                }
                label={t('signup-complete.input-allow-extra-emails')}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitNotAllowed}
          >
            {t('signup-complete.submit')}
          </Button>

        </Box>

      </Box>
    </>
  )
}

import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'

// src
import { validateEmail, ValidationResult } from '~src/models/validations'

type EmailFieldProps = {
  id: string
  name: string
  label: string
  defaultValue?: string
  disabled?: boolean
  readonly?: boolean
  onValidationResultChanged?: (value: ValidationResult) => void
}

export function EmailField (props: EmailFieldProps) {
  const { t } = useTranslation()
  const [emailVR, setEmailVR] = useState<ValidationResult>()

  // メールアドレスの検証
  const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    const vr = validateEmail(text)
    setEmailVR(vr)
    if (props.onValidationResultChanged) {
      props.onValidationResultChanged(vr)
    }
  }

  const showError = emailVR?.invalid ?? false
  const showErrorText = emailVR?.invalid ? t(emailVR?.tid!) : undefined

  return (
    <TextField
      required
      fullWidth
      id={props.id}
      name={props.name}
      label={props.label}
      error={showError}
      helperText={showErrorText}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      inputProps={{
        readOnly: props.readonly
      }}
      onChange={handleEmailChanged}
      autoComplete="email"
    />
  )
}

import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { parseError } from '~src/models/apis/util'
import { useRootSelector } from '~src/stores'

type ChangePasswordMutateInput = {
  currentPassword: string
  newPassword: string
}

export function useChangePassword () {
  const user = useRootSelector(state => state.auth.user)
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState(false)
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: ChangePasswordMutateInput) => {
    // ユーザー認証必須
    if (user == null) {
      const err: Error & { code?: string } = new Error('Unauthenticated')
      err.code = 'unauthenticated-access'
      setError(err)
      throw err
    }

    // 開始
    setResult(false)
    setError(undefined)
    setIsValidating(true)

    // firebase reauthenticate with email & password
    try {
      const cred = EmailAuthProvider.credential(
        user.email ?? '',
        input.currentPassword
      )
      await reauthenticateWithCredential(user, cred)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // firebase update password
    try {
      await updatePassword(user, input.newPassword)
      setResult(true)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setIsValidating(false)
    return true
  }, [])

  const reset = useCallback(() => {
    setResult(false)
    setError(undefined)
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// icons
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import SendIcon from '@mui/icons-material/Send'

// src
import { useAppDispatch, useRootSelector } from '~/src/stores'
import { actions } from '~/src/stores/slices/signup'

export type SignUpVerifyProps = {
  children?: React.ReactNode,
}

export function SignUpVerify (props: SignUpVerifyProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const emailLinkState = useRootSelector(state => state.signup.emailLinkSigningState)

  useEffect(() => {
    dispatch(actions.signInWithEmailLink())
  }, [])

  const handleContinue = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    navigate('../complete', { replace: true })
  }

  const renderMessage = () => {
    switch (emailLinkState) {
      case 'failed':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <CancelScheduleSendIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-verify.message-failed')}
            </Typography>
          </>
        )

      case 'signed':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <MarkEmailReadIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-verify.message-signed')}
            </Typography>
          </>
        )

      case 'signing':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <SendIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signup-verify.message-signing')}
            </Typography>
          </>
        )

      default:
        return (
          <></>
        )
    }
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* タイトルメッセージ */}
        {renderMessage()}

        {/* 続ける */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleContinue}
          disabled={emailLinkState !== 'signed'}
          sx={{ mt: 3 }}
        >
          {t('signup-verify.button-continue')}
        </Button>

      </Box>
    </>
  )
}

import { FirebaseError } from 'firebase/app'
import {
  deleteUser as deleteAuthUser, EmailAuthProvider,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User as AuthUser,
  UserCredential
} from 'firebase/auth'
import log from 'loglevel'
import { EventChannel, eventChannel } from 'redux-saga'
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects'

// src
import { authSignOut, federatedSignIn } from '~/src/models/amplify'
import { deleteUser, fetchUser, FetchUserResult, updateUser, UpdateUserResult } from '~/src/models/apis/private'
import { verifyAction, VerifyActionResult } from '~/src/models/apis/public'
import { auth } from '~/src/models/firebase'
import { RootState } from '~/src/stores'
import { actions as authActions } from '~/src/stores/slices/auth'

type AuthStateAction =
  ReturnType<typeof authActions.signedIn> |
  ReturnType<typeof authActions.signedOut>

function initAuthStateChannel () {
  return eventChannel<AuthStateAction>(emitter => {
    log.debug('onAuthStateChanged - started')

    // subscribe
    const unsubscribe = onAuthStateChanged(auth,
      (user) => {
        if (user != null) {
          // signed in
          log.debug('onAuthStateChanged - signed in')
          emitter(authActions.signedIn({ user }))
        } else {
          // signed out
          log.debug('onAuthStateChanged - signed out')
          emitter(authActions.signedOut())
        }
      })

    // cleanup
    return () => {
      log.debug('onAuthStateChanged - unsubscribe')
      unsubscribe()
    }
  })
}

function * handleAuthState () {
  const channel: EventChannel<AuthStateAction> = yield call(initAuthStateChannel)
  while (true) {
    const action: AuthStateAction = yield take(channel)
    yield put(action)

    // 認証イベント後の処理
    const { type, payload } = action
    if (type === authActions.signedIn.type) {
      try {
        // AWS(Identity Pool)にもログイン
        yield call(federatedSignIn, payload!.user)
      } catch (err) {
        // エラー
        yield put(authActions.authError({ code: 'federated-signin-error', err }))
        return
      }

      // ユーザ情報を取得する
      yield put(authActions.fetchUserAccount())
    } else if (type === authActions.signedOut.type) {
      // AWS(Identity Pool)からもサインアウト
      yield call(authSignOut)
    }
  }
}

// サインイン（Eメールとパスワードでログイン）のリクエストを処理する
function * handleRequestSignIn (action: ReturnType<typeof authActions.signIn>) {
  const { email, password, token } = action.payload
  log.debug('sign-in start', email)

  try {
    // ユーザーがパスワードでサインイン可能か確認する
    const methods: string[] = yield call(fetchSignInMethodsForEmail, auth, email)
    log.debug('sign-in fetch methods', methods)
    if (methods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) === -1) {
      // パスワードでサインインできないエラー
      yield put(authActions.authError({ code: 'password-not-support' }))
      return
    }

    // ユーザーアクションを検証する
    const resp: VerifyActionResult = yield call(verifyAction, { action: 'signin', email, responseToken: token })
    log.debug('sign-in verfy action', resp)
    if (!resp.verifyAction.success) {
      // verifyAction failed
      yield put(authActions.authError({ code: 'verify-action-failure' }))
      return
    }

    const data: UserCredential = yield call(signInWithEmailAndPassword, auth, email, password)
    log.debug('sign-in success', data.user.email)
    // yield put(actions.signedIn({ user: data.user })) // -- handleAuthStateで呼ばれるので不要
  } catch (err) {
    log.error('sign-in error', err)
    if (err instanceof FirebaseError) {
      yield put(authActions.authError({ code: err.code, err }))
    } else {
      yield put(authActions.authError({ code: 'sign-in-error', err }))
    }
  }
}

// サインアウトのリクエストを処理する
function * handleRequestSignOut (action: ReturnType<typeof authActions.signOut>) {
  log.debug('sign-out start')
  try {
    yield call(signOut, auth)
    log.debug('sign-out success')
    // yield put(actions.signedOut()) // -- handleAuthStateで呼ばれるので不要
  } catch (err) {
    log.error('sign-out error', err)
    if (err instanceof FirebaseError) {
      yield put(authActions.authError({ code: err.code, err }))
    } else {
      yield put(authActions.authError({ code: 'sign-out-error', err }))
    }
  }
}

// アカウント取得のリクエストを処理する
function * handleRequestFetchUserAcount (action: ReturnType<typeof authActions.fetchUserAccount>) {
  try {
    const user: AuthUser = yield select((state: RootState) => state.auth.user)
    const result: FetchUserResult = yield call(fetchUser, user.uid)
    let userAccount = result.user
    if (user.email != null && userAccount != null && user.email !== userAccount.email) {
      try {
        // もし email address が違う場合は更新する (エラー対策)
        const resultUpdate: UpdateUserResult = yield call(updateUser, {
          userId: userAccount.id,
          uid: user.uid,
          email: user.email
        })
        userAccount = resultUpdate.updateUser.user
      } catch (err) {
        log.error('fetch-user error on updateUser()', err)
      }
    }
    yield put(authActions.userAccountFetched({ account: userAccount }))
  } catch (err) {
    yield put(authActions.authError({ code: 'fetch-user-error', err }))
  }
}

// アカウント削除のリクエストを処理する
function * handleRequestDeleteUserAccount (action: ReturnType<typeof authActions.deleteUserAccount>) {
  const { input } = action.payload
  try {
    const user: AuthUser = yield select((state: RootState) => state.auth.user)
    yield call(deleteAuthUser, user)
    yield call(deleteUser, input)
  } catch (err) {
    yield put(authActions.authError({ code: 'delete-user-error', err }))
  }
}

export default function * authSaga () {
  yield fork(handleAuthState)
  yield takeLatest(authActions.signIn, handleRequestSignIn)
  yield takeLatest(authActions.signOut, handleRequestSignOut)
  yield takeLatest(authActions.fetchUserAccount, handleRequestFetchUserAcount)
  yield takeLatest(authActions.deleteUserAccount, handleRequestDeleteUserAccount)
}

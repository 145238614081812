import { gql, request } from 'graphql-request'

export const graphqlEndpoint = process.env.USERFRONTAPI_GRAPHQL_PUBLIC!

export type VerifyActionInput = {
  action: string
  email: string
  responseToken: string
}

export type VerifyActionPayload = {
  success: Boolean
  errorCodes: string[]
}

export type VerifyActionResult = {
  verifyAction: VerifyActionPayload
}

export async function verifyAction (input: VerifyActionInput) {
  const query = gql`
mutation VerifyAction($input: VerifyActionInput!) {
  verifyAction(input: $input) {
    success
    errorCodes
  }
}
`
  const response = await request<VerifyActionResult>(graphqlEndpoint, query, { input })
  return response
}

import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { actions as authActions } from './auth'
import { actions as signupActions } from './signup'

interface LoadingStateStore {
  isLoading: boolean
}

const initialState: LoadingStateStore = {
  isLoading: false
}

const sliceName = 'loading'

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(
        signupActions.sendEmailLink,
        signupActions.signInWithEmailLink,
        signupActions.completeSignup,
        authActions.signIn,
        authActions.signOut,
        authActions.fetchUserAccount
      ), (state, action) => {
        state.isLoading = true
      })
      .addMatcher(isAnyOf(
        signupActions.sendingEmailLinkIsSuccessful,
        signupActions.sendingEmailLinkIsFailed,
        signupActions.signingEmailLinkIsSuccessful,
        signupActions.signingEmailLinkIsFailed,
        signupActions.signupError,
        authActions.signedIn,
        authActions.signedOut,
        authActions.userAccountFetched,
        authActions.authError
      ), (state, action) => {
        state.isLoading = false
      })
  }
})

// Actions
export const actions = slice.actions

// Reducer
export default slice.reducer

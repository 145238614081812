export type ValidationResult = {
  invalid: boolean,
  tid?: string
}

function valid (): ValidationResult { return { invalid: false } }

function invalid (tid: string): ValidationResult { return { invalid: true, tid } }

export function isInvalidAny (...args: ValidationResult[]): boolean {
  return args.some(it => it.invalid)
}

export type ValidatorFunc<T> = (value: T | null | undefined) => ValidationResult

// パスワード検証
export const validateEmail: ValidatorFunc<string> = (value) => {
  // Eメールが空白でないか確認
  if (value == null) {
    return invalid('validation.email-is-empty')
  }
  value = value.trim()
  if (value.length <= 0) {
    return invalid('validation.email-is-empty')
  }

  // Eメールの文字列パターンかどうか
  // 参考: https://emailregex.com/
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(value)) {
    return invalid('validation.email-is-invalid')
  }

  // Eメール要件OK
  return valid()
}

// パスワード要件
const passwordAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const passwordNumbers = '0123456789'
const passwordSymbols = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
const passwordLength = 8

// パスワード検証
export const validatePassword: ValidatorFunc<string> = (value) => {
  // パスワードが十分な長さか確認
  if (value == null) {
    return invalid('validation.password-is-too-short')
  }
  if (value.length < passwordLength) {
    return invalid('validation.password-is-too-short')
  }

  // 有効な文字以外が含まれていないか確認
  const re = new RegExp(`[^${passwordAlphabet}${passwordNumbers}${passwordSymbols}]`)
  if (re.test(value)) {
    return invalid('validation.password-is-invalid')
  }

  // それぞれが１文字ずつ含まれているか確認
  const checks = [
    passwordAlphabet,
    passwordNumbers,
    passwordSymbols
  ]
  for (let i = 0; i < checks.length; i++) {
    const re = new RegExp(`[${checks[i]}]`)
    if (!re.test(value)) {
      return invalid('validation.password-is-insecure')
    }
  }

  // パスワード要件OK
  return valid()
}

// カード番号要件
const cardNumbers = '0123456789'
const cardLengthMin = 14
const cardLengthMax = 19

// カード番号検証
export const validateCard: ValidatorFunc<string> = (value) => {
  // カード番号が十分な長さか確認
  if (value == null) {
    return invalid('validation.card-number-length-is-not-in-the-range')
  }
  value = value.replace(/\s/g, '')
  if (value.length < cardLengthMin) {
    return invalid('validation.card-number-length-is-not-in-the-range')
  }
  if (value.length > cardLengthMax) {
    return invalid('validation.card-number-length-is-not-in-the-range')
  }

  // 有効な文字以外が含まれていないか確認
  const re = new RegExp(`[^${cardNumbers}]`)
  if (re.test(value)) {
    return invalid('validation.card-number-is-invalid')
  }

  // カード番号要件OK
  return valid()
}

// カード有効期限要件
const cardExpiryLength = 4

// カード有効期限検証
export const validateCardExpiry: ValidatorFunc<string> = (value) => {
  // カード有効期限が十分な長さか確認
  if (value == null) {
    return invalid('validation.card-expiry-length-is-not-in-the-range')
  }
  value = value.replace(/\s/g, '')
  if (value.length !== cardExpiryLength) {
    return invalid('validation.card-expiry-length-is-not-in-the-range')
  }

  // 年
  const valueYY = value.substring(0, 2)
  const numYY = parseInt(valueYY, 10)
  if (numYY >= 0 && numYY <= 99) {
    // OK
  } else {
    return invalid('validation.card-expiry-is-invalid')
  }

  // 月
  const valueMM = value.substring(2)
  const numMM = parseInt(valueMM, 10)
  if (numMM >= 1 && numMM <= 12) {
    // OK
  } else {
    return invalid('validation.card-expiry-is-invalid')
  }

  // カード有効期限要件OK
  return valid()
}

// カード名義人検証
export const validateCardHolderName: ValidatorFunc<string> = (value) => {
  // カード有効期限が十分な長さか確認
  if (value == null) {
    return invalid('validation.card-holder-name-length-is-not-in-the-range')
  }
  value = value.trim()
  if (value.length <= 0) {
    return invalid('validation.card-holder-name-length-is-not-in-the-range')
  }

  // 無効な文字列が含まれていないか
  if (/[^A-Z ]/.test(value)) {
    return invalid('validation.card-holder-name-is-invalid')
  }

  // 要件OK
  return valid()
}

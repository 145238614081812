import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// src
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { useRegisterCard } from '~src/hooks/credit-cards'

export function RegistCardResult () {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  // const navigate = useNavigate()

  const systemCardId = searchParams.get('systemcardid')

  const { isValidating, mutate, data, errorInfo: err } = useRegisterCard()
  const [result, setResult] = React.useState<'Success' | 'Error' | 'Loading' | 'Stopped'>('Loading')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (systemCardId) {
        mutate({
          SystemCardId: systemCardId
        }).catch(_ => { })
      } else {
        setResult('Stopped')
      }
    }, 300)
    return () => clearTimeout(timer)
  }, [systemCardId])

  useEffect(() => {
    if (!isValidating && data) {
      setResult('Success')
      const timer = setTimeout(() => {
        // navigate('./../../cards')
        window.close()
      }, 3000)
      return () => clearTimeout(timer)
    }
    if (!isValidating && err) {
      setResult('Error')
    }
  }, [isValidating, data, err])

  const renderMessage = () => {
    switch (result) {
      case 'Success':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <CheckCircleOutlineIcon />
            </Avatar>
            <Typography component="h1" variant="h5">{t('regist-card-result.title-success')}</Typography>
          </>
        )

      case 'Error':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <ErrorOutlineIcon />
            </Avatar>
            <Typography component="h1" variant="h5">{t('regist-card-result.title-error')}</Typography>
          </>
        )

      case 'Loading':
        return (
          <>
            <Typography component="h1" variant="h5">{t('regist-card-result.title-loading')}</Typography>
          </>
        )

      case 'Stopped':
        return (
          <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <ErrorOutlineIcon />
            </Avatar>
            <Typography component="h1" variant="h5">{t('regist-card-result.title-stopped')}</Typography>
          </>
        )

      default:
        return (
          <></>
        )
    }
  }

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    // navigate('./../../cards')
    window.close()
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* タイトルメッセージ */}
        {renderMessage()}

        {/* 戻る */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleBack}
          disabled={result === 'Loading'}
          sx={{ mt: 3 }}
        >
          {t('regist-card-result.button-back')}
        </Button>

      </Box>

      <LoadingProgressSimple open={result === 'Loading'} />
    </>
  )
}

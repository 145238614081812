import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

// css
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// log
import './models/log'

// ./
import { App } from './app'
import i18n, { applyLanguage } from './locales/i18n'
import './models/amplify'
import { store } from './stores'

i18n.on('languageChanged', (lng) => {
  applyLanguage(lng)
})
applyLanguage(i18n.language)

// ReactDOM.render is no longer supported in React 18...
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById('app')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
)

// Hot Module Replacement（HMR） -- development only
if (module.hot) {
  module.hot.dispose(function () {
    // module is about to be replaced
    i18n.off('languageChanged')
  })
  module.hot.accept(function () {
    // module or one of its dependencies was just updated
  })
}

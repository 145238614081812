import { GraphQLResult } from '@aws-amplify/api-graphql'
import { GraphQLError } from 'graphql'

export type ErrorInfo = {
  code: string,
  err?: GraphQLError | Error | undefined
}

export function parseError (err: any): ErrorInfo | undefined {
  if (err == null) {
    return
  }
  const gqle = err as GraphQLResult
  if (gqle.errors && gqle.errors.length > 0) {
    const e = gqle.errors[0]
    const code = e.message.split(':').pop()?.trim() ?? 'unknown'
    return {
      code,
      err: e
    }
  }
  if (err.code != null) {
    return {
      code: err.code,
      err
    }
  }
  if (err instanceof Error) {
    return {
      code: typeof err,
      err
    }
  }
  return {
    code: 'unknown',
    err
  }
}

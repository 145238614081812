import { useCallback, useState } from 'react'
import { updateUser, UpdateUserInput } from '~src/models/apis/private'
import { parseError } from '~src/models/apis/util'
import { useAppDispatch, useRootSelector } from '~src/stores'
import { actions } from '~src/stores/slices/auth'

type UpdateUserMutateInput = {
  userName?: string,
  kana?: string,
  allowExtraEmails?: boolean,
}

export function useUpdateUser () {
  const dispatch = useAppDispatch()
  const user = useRootSelector(state => state.auth.user)
  const userAccount = useRootSelector(state => state.auth.account)
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState(false)
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: UpdateUserMutateInput) => {
    // ユーザー認証必須
    if (user == null || userAccount == null) {
      const err: Error & { code?: string } = new Error('Unauthenticated')
      err.code = 'unauthenticated-access'
      setError(err)
      throw err
    }

    // 開始
    setResult(false)
    setError(undefined)
    setIsValidating(true)

    // update user
    try {
      const req: UpdateUserInput = {
        userId: userAccount.id,
        uid: user.uid
      }
      if (input.userName != null) {
        req.userName = input.userName
      }
      if (input.kana != null) {
        req.kana = input.kana
      }
      if (input.allowExtraEmails != null) {
        req.allowExtraEmails = input.allowExtraEmails
      }

      const resultUpdate = await updateUser(req)
      // アプリケーション状態を更新
      dispatch(actions.userAccountFetched({ account: resultUpdate.updateUser.user }))
      setResult(true)
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setIsValidating(false)
    return true
  }, [])

  const reset = useCallback(() => {
    setResult(false)
    setError(undefined)
  }, [])

  return {
    isValidating,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

import { useCallback, useState } from 'react'
import useSWRInfinite from 'swr/infinite'

// src
import { addCreditCard, CreditCard, deleteCreditCard, fetchCreditCards, FetchCreditCardsInput } from '~src/models/apis/private'
import { parseError } from '~src/models/apis/util'
import { useCardAPIClient } from '~src/models/card-api'
import { useRootSelector } from '~src/stores'
import { useKeep } from './keep'

// fetchCreditCards
export function useCreditCards () {
  // user id
  const user = useRootSelector(state => state.auth.user)
  const userId = user?.uid ?? ''

  // fetch
  const { error, isValidating, ...restProps } = useSWRInfinite(
    (index, previousData) => {
      const input: FetchCreditCardsInput = { first: 10 }
      const prevConnection = previousData?.user?.creditCards
      if (prevConnection) {
        input.after = prevConnection.pageInfo.endCursor
      }
      return [userId, input]
    },
    fetchCreditCards,
    {
      dedupingInterval: 60000,
      shouldRetryOnError: false
    })

  // isValidating
  const isValidatingKeep = useKeep(isValidating, 1500)

  // data
  return {
    isValidating: isValidatingKeep,
    errorInfo: parseError(error),
    error,
    ...restProps
  }
}

type RegisterCardMutateInput = {
  SystemCardId: string
}

// addCreditCard
export function useRegisterCard () {
  const userAccount = useRootSelector(state => state.auth.account)
  const api = useCardAPIClient()
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState<CreditCard | undefined>()
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: RegisterCardMutateInput) => {
    // 開始
    setResult(undefined)
    setError(undefined)
    setIsValidating(true)

    // userfront: addCreditCard
    let creditCard: CreditCard
    try {
      const card = await addCreditCard({
        userId: userAccount!.id,
        systemCardId: input.SystemCardId
      })
      creditCard = card.addCreditCard.creditCard
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setResult(creditCard)
    setIsValidating(false)
    return creditCard
  }, [api, userAccount])

  const reset = useCallback(() => {
    setResult(undefined)
    setError(undefined)
  }, [api])

  const isValidatingKeep = useKeep(isValidating, 1500)

  return {
    isValidating: isValidatingKeep,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

type DeleteCardMutateInput = {
  item: CreditCard
}

// deleteCreditCard
export function useDeleteCard () {
  const [isValidating, setIsValidating] = useState(false)
  const [result, setResult] = useState<CreditCard | undefined>()
  const [error, setError] = useState<any>()

  const mutate = useCallback(async (input: DeleteCardMutateInput) => {
    // 開始
    setResult(undefined)
    setError(undefined)
    setIsValidating(true)

    // userfront: deleteCreditCard
    let creditCard: CreditCard
    try {
      const card = await deleteCreditCard({
        id: input.item.id
      })
      creditCard = card.deleteCreditCard.creditCard
    } catch (err) {
      setError(err)
      setIsValidating(false)
      throw err
    }

    // 終了
    setResult(creditCard)
    setIsValidating(false)
    return creditCard
  }, [])

  const reset = useCallback(() => {
    setResult(undefined)
    setError(undefined)
  }, [])

  const isValidatingKeep = useKeep(isValidating, 1500)

  return {
    isValidating: isValidatingKeep,
    errorInfo: parseError(error),
    error,
    data: result,
    mutate,
    reset
  }
}

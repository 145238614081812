import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import { Alert, Button, Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'

// src
import { LoadingProgressSimple } from '~src/components/loading-progress'
import { PasswordField } from '~src/components/password-field'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useChangePassword } from '~src/hooks/change-password'
import { validatePassword, ValidationResult } from '~src/models/validations'

export function ChangePassword () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isValidating, mutate, data, errorInfo: err, reset } = useChangePassword()
  const passwordChangeSucceeded = (!isValidating && data)

  // パスワード関連
  const [passwordCurrentText, setPasswordCurrentText] = useState<string>()
  const [passwordCurrentVR, setPasswordCurrentVR] = useState<ValidationResult>()
  const [passwordNewText, setPasswordNewText] = useState<string>()
  const [passwordConfirmText, setPasswordConfirmText] = useState<string>()
  const [passwordNewVR, setPasswordNewVR] = useState<ValidationResult>()
  const [passwordConfirmVR, setPasswordConfirmVR] = useState<ValidationResult>()

  const validatePasswordCurrent = (password: string) => {
    const v: ValidationResult = {
      invalid: false
    }
    const invalid = password.trim().length <= 0
    if (invalid) {
      v.invalid = true
      v.tid = 'change-password.password-current-is-invalid'
    }
    return v
  }

  const validatePasswordNew = (password: string, passwordCurrent: string) => {
    const v = validatePassword(password)
    if (!v.invalid && password === passwordCurrent) {
      v.invalid = true
      v.tid = 'change-password.password-new-is-same'
    }
    return v
  }

  const validatePasswordConfirm = (password: string, passwordConfirm: string) => {
    const v: ValidationResult = {
      invalid: false
    }
    if (password !== passwordConfirm) {
      v.invalid = true
      v.tid = 'validation.password-is-not-same'
    }
    return v
  }

  const handlePasswordCurrentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordCurrentText(text)
    setPasswordCurrentVR(validatePasswordCurrent(text ?? ''))
    if (passwordNewText) {
      setPasswordNewVR(validatePasswordNew(passwordNewText, text))
    }
  }

  const handlePasswordNewChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordNewText(text)
    setPasswordNewVR(validatePasswordNew(text, passwordCurrentText ?? ''))
    if (passwordConfirmText) {
      setPasswordConfirmVR(validatePasswordConfirm(text, passwordConfirmText))
    }
  }

  const handlePasswordConfirmChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const text = event.target.value
    setPasswordConfirmText(text)
    setPasswordConfirmVR(validatePasswordConfirm(passwordNewText ?? '', text))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const currentPassword = data.get('password-current') as string
    const newPassword = data.get('password-new') as string

    // パスワード変更
    mutate({ currentPassword, newPassword }).catch(_ => { })
  }

  const handleBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(-1)
  }

  const submitNotAllowed = (
    (passwordCurrentVR?.invalid ?? true) ||
    (passwordNewVR?.invalid ?? true) ||
    (passwordConfirmVR?.invalid ?? true) ||
    passwordChangeSucceeded
  )

  return (
    <>
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('change-password.title')}
        </Typography>

        {/* 成功表示: resetPassword */}
        {(passwordChangeSucceeded) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="success">
              {t('change-password.success')}
            </Alert>
          </Stack>
        }

        {/* エラー表示: resetPassword */}
        {(!isValidating && err) &&
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity="error" onClose={() => reset()}>
              {ErrorInfoMessage(err)}
            </Alert>
          </Stack>
        }

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>

            {/* 現在のパスワード */}
            <Grid item xs={12}>
              <PasswordField
                id="change-password-current"
                name="password-current"
                label={t('change-password.input-password-current')}
                validationResult={passwordCurrentVR}
                onChange={handlePasswordCurrentChanged}
                readonly={passwordChangeSucceeded}
              />
            </Grid>

            {/* 新しいパスワード */}
            <Grid item xs={12}>
              <PasswordField
                id="change-password-new"
                name="password-new"
                label={t('change-password.input-password-new')}
                autoComplete="new-password"
                validationResult={passwordNewVR}
                onChange={handlePasswordNewChanged}
                readonly={passwordChangeSucceeded}
              />
            </Grid>

            {/* 新しいパスワード（確認） */}
            <Grid item xs={12}>
              <PasswordField
                id="change-password-confirm"
                name="password-confirm"
                label={t('change-password.input-password-confirm')}
                autoComplete="new-password"
                validationResult={passwordConfirmVR}
                onChange={handlePasswordConfirmChanged}
                readonly={passwordChangeSucceeded}
              />
            </Grid>
          </Grid>

          {/* パスワード変更 */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={submitNotAllowed}
          >
            {t('change-password.submit')}
          </Button>

          {/* 戻る */}
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={handleBackClick}
          >
            {t('change-password.back')}
          </Button>
        </Box>
      </Box>

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}

import { useEffect, useRef, useState } from 'react'

export function useKeep (start: boolean, timeoutMs: number): boolean {
  const [version, setVersion] = useState(1)
  const keepValue = useRef(false)

  useEffect(() => {
    if (keepValue.current) {
      return
    }
    if (start) {
      // keep
      keepValue.current = true

      // clear
      setTimeout(() => {
        keepValue.current = false
        setVersion(version + 1)
      }, timeoutMs)
    }
  }, [start])

  return start || keepValue.current
}
